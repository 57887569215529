import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";
import {
  GET_SUB_LIST_ERROR,
  GET_SUB_LIST_SUCESS,
  GET_SUB_LOADER,
  POST_SUB_ERROR,
  POST_SUB_SUCESS,
} from "../../Type";

export const getHomeDataSuccess = (item) => {
  return {
    type: GET_SUB_LOADER,
    payload: true,
  };
};

export const userSelectedSub = (data) => {
  return {
    type: "USER_SELECTED_SUB",
    payload: data,
  };
};

export const deleteUserSelectedSub = (data) => {
  return {
    type: "DELETE_USER_SELECTED_SUB",
    payload: data,
  };
};

export const getSubList = (payload) => (dispatch) => {
  dispatch({
    type: "SET_IS_DONE",
  });
  axios
    .post(endpoints.subList, payload)
    .then((res) => {
      if (res.data.code === 1) {
        dispatch({
          type: GET_SUB_LIST_SUCESS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: GET_SUB_LIST_ERROR,
          payload: '',
        });
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch({
        type: GET_SUB_LIST_ERROR,
        payload: err.data,
      });
    });
};
export const saveSubDetails = (payload) => (dispatch) => {
  axios
    .post(endpoints.usersub, payload)
    .then((res) => {
      if (res.data.code === 1) {
        dispatch({
          type: POST_SUB_SUCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: POST_SUB_ERROR,
          payload: '',
        });
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch({
        type: POST_SUB_ERROR,
        payload: err.data,
      });
    });
};
