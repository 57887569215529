import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { endpoints } from "../../redux/endpoint";

import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../assets/delete-icon.png";
import FoodImage from "../../assets/food-img.png";
import minusImg from "../../assets/minus.png";
import plusImg from "../../assets/plus.png";
import CartBanner from "../../components/CartBanner/CartBanner";
import {
  addToCart,
  replaceCart,
} from "../../redux/addToCart/actions/addToCart.action";
import {
  getProfile
} from "../../redux/editProfile/actions/editProfile.actions";
import {
  cartList,
  getCartTotal,
  getCartDataSuccess,
  getCartDataFailure
} from "../../redux/cartList/actions/cartList.action";
import { getAddToCartSuccess, getAddToCartFailure } from "../../redux/addToCart/actions/addToCart.action";
import "./cart.scss";
import { applyCouponeCode } from "../../redux/verifyCoupon/verifyCoupon";
import { Popconfirm } from "antd";
import { Box, Button, Popover } from "@mui/material";
import { getLocalState } from "../../common/localstorage";
import { NotificationManager } from "react-notifications";

const Cart = () => {
  const promoCodeData = useSelector((state) => state?.promocodeReducer?.data);

  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [promocode, setPromoCode] = useState(promoCodeData?.promocode ?? "");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [openPopover, setOpenPopover] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState();
  const [cartTotal, setCartTotal] = useState([
    {
      name: "subtotal",
      amount: 43.99,
      shipping: "",
    },
    {
      name: "Shipping",
      amount: 43.99,
      shipping: "Free Shipping",
    },
    {
      name: "Internet Convenience Fee",
      amount: 1.76,
      shipping: "",
    },
    {
      name: "Tax",
      amount: 2.91,
      shipping: "",
    },
    {
      name: "Total",
      amount: 1.76,
      shipping: "",
    },
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
  }, []);
  const state = useSelector((state) => state?.stateListReducer?.selectedState);
  console.log("state data", state);

  const addCartData = useSelector((state) => state?.cartListReducer?.data);
  const subscription = useSelector(
    (state) => state?.cartListReducer?.subscription
  );
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  const getCartList = () => {
    axios
      .post(endpoints.cartList)
      .then((res) => {
        setLoading(false)
        setApiLoading(false)
        const parsed = JSON.parse(JSON.stringify(res));
        console.log("MAIN RESPONSE", res, parsed.data);
        if (parsed.data.code == 1) {
          console.log("INSIDE", parsed.data.data ?? []);
          dispatch({
            type: "GET_CART_DATA_SUCCESS",
            payload: parsed.data.data ?? [],
          });
        } else if (parsed.data.code == 2) {
          dispatch(getCartDataSuccess([]));
        } else {
          // NotificationManager.error(parsed.data.message);
        }
      })
      .catch((err) => {
        // NotificationManager.error(err.message);
        setApiLoading(false)
        dispatch(getCartDataFailure(err.message));
      });
  }

  useEffect(() => {
    setLoading(true);
    if (token) {
      getCartList()
    } else {
      setLoading(false)
      setApiLoading(false)
    }
  }, []);

  useEffect(() => {
    if (addCartData) {
      setData(addCartData);
      setLoading(false);
      if (!token) setApiLoading(false)
      console.log("CRRD DATA", addCartData);
      getSubscription(addCartData);
    }
  }, [addCartData]);

  useEffect(() => {
    if (subscription) {
      console.log("SUBSCRIPTION", subscription);
      setSubscriptionData(subscription);
    }
  }, [subscription]);

  useEffect(() => {
    if (data) {
      let total = 0;
      // const subscriptions = subscriptionD
      data?.map((i) => {
        const qty = i?.quantity !== 0 ? i?.quantity : 1;
        total = total + i?.price * qty;
      });
      // setData(cartData);

      if (promoCodeData?.value) {
        total = total - (total * promoCodeData?.value) / 100;
      }

      const taxApplied = state == '3' ? 10 : 6.625;

      const tax = (total * taxApplied) / 100;
      const internetFee = (total * 4) / 100;
      const cartData = cartTotal;
      cartData[2].amount = parseFloat(internetFee).toFixed(2);
      cartData[3].amount = parseFloat(tax).toFixed(2);
      setCartTotal(cartData);
      setTotal(total + internetFee + tax);
      setSubTotal(total);
      // localStorage.setItem('checkout_sub_total', total);
      // localStorage.setItem('checkout_total', total + 1.76 + 2.91);
      dispatch(getCartTotal(total));
      // console.log("price", total)
    }
  }, [data, promoCodeData]);

  const getSubscription = (arry) => {
    let temp = JSON.parse(JSON.stringify(arry));
    const hasSubscription = subscription?.filter(
      (j) => j?.use_quantity < j?.quantity
    );
    arry?.map((i, index) => {
      const includes = hasSubscription?.filter(
        (j) => j?.tiffin_id == i?.tiffin_id
      );
      if (i?.type === "Tiffin" && includes?.length) {
        // console.log("IIIIII", i?.price,includes[0]?.price)
        // temp[index].price = 0;
        temp[index].price = i?.price - includes[0]?.price;
      }
    });
    // console.log("TE", temp)
    setData(temp);
  };

  const handleCheckout = () => {
    if ((state === 6 || state === '6') && subTotal < 69.99) {
      NotificationManager.error('Please make sure cart value is greater than $69.99')
    } else {
      localStorage.setItem("is_subscription", false);
      navigate("/checkout");
    }
  };

  const addToCartFun = (payload) => {
    axios
      .post(endpoints.addToCart, payload)
      .then((res) => {
        if (res.data.code === 1) {
          console.log({ res });
          dispatch(getAddToCartSuccess(res.data));
          // dispatch(addCart(res.data))
          getCartList()
          // setLoading(false)
          // setApiLoading(false)
          NotificationManager.success(res.data.message);
        } else {
          // NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
        dispatch(getAddToCartFailure(err.message));
      });
  }

  const handleQuantity = (
    product_id,
    quantity,
    price,
    type,
    localData = [],
    tiffin_id = -1
  ) => {
    setLoading(true);
    let payload = {
      state_id: 1,
      product_id: product_id,
      quantity: quantity,
      price: price,
      type: type,
    };
    if (tiffin_id > 0) {
      payload.tiffin_id = tiffin_id;
    }
    if (token) {
      addToCartFun(payload)
    } else {
      dispatch(replaceCart(localData));
    }
  };


  const handleDelete = () => {
    const item = popoverData
    setLoading(true)
    setApiLoading(true)
    setOpenPopover(false);
    setAnchorEl(null);
    setPopoverData({});
    let payload = {
      state_id: 1,
      product_id: item?.product_id,
      quantity: 0,
      price: item?.price,
      type: item?.type,
    };
    if (item?.tiffin_id) {
      payload = {
        ...payload,
        tiffin_id: item?.tiffin_id,
        tiffin_detail_id: item?.tiffin_detail_id,
      };
    }
    if (token) {
      addToCartFun(payload)
    } else {
      const temp = JSON.parse(JSON.stringify(data));
      const deletedData = temp?.filter((i) =>
        i?.type == "Tiffin"
          ? i?.tiffin_id !== item?.tiffin_id
          : i?.type == "Single"
            ? i?.product_id !== item?.product_id
            : i?.id !== item?.id
      );
      dispatch(replaceCart(deletedData));
    }
  };

  const handleChange = (e) => {
    setPromoCode(e.target.value);
  };

  const applyCoupon = () => {
    const data = {
      state_id: state,
      promocode: promocode,
    };
    if (!getLocalState("userData")) {
      NotificationManager.warning("Please Login to Continue");
    } else {
      dispatch(applyCouponeCode(data));
    }
  };

  return (
    <div className="cart-wrapper">
      <div
        className="loading-backdrop"
        style={{
          display: apiLoading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      {/* <CartBanner /> */}
      <h1
        style={{
          marginTop: "20px",
          fontSize: "36px",
          color: "#606060",
        }}
      >
        Your Cart
      </h1>
      {!loading && (
        <div className="product-details-wrapper">
          {data?.length > 0 ? (
            <>
              <div className="product-details">
                <div className="product-table">
                  <div className="product-head">
                    <div className="tr">
                      <div data-name="Product Details" className="th large-w">
                        Product Details
                      </div>
                      <div data-name="Price" className="th">
                        Price
                      </div>
                      <div data-name="Quality" className="th">
                        Quality
                      </div>
                      <div data-name="Total" className="th">
                        Total
                      </div>
                    </div>
                  </div>
                  {data.map((item, index) => {
                    const total = item.price * item.quantity;
                    return (
                      <>
                        <div className="product-body">
                          <div className="tr">
                            <div className="td large-w">
                              <div className="product-info">
                                <div className="product-info-detail">
                                  <img
                                    src={item?.image || FoodImage}
                                    alt="Product Images"
                                  />
                                  <div className="detail">
                                    <div className="item-detail">
                                      {/* <span>
                                Weekday Tiffin Service Delivery on Mon - Wed -
                                Friday Price $ 43.99
                              </span> */}
                                      <h3>
                                        {item.name ? item.name : item.title}
                                      </h3>
                                      {item.type === 'Single' ?
                                        <h3 className='product-type'>
                                          {item?.product_type == 2 ? 'Small' : item?.product_type == 3 ? 'Medium' : item?.product_type == 4 ? 'Large' : ''}
                                        </h3> : ''
                                      }
                                    </div>
                                    <div className="item-detail">
                                      {item?.tiffin_detail_id &&
                                        item?.tiffin_detail_id?.map((i) => (
                                          <span>
                                            {i?.descripion +
                                              " x " +
                                              i?.quantity +
                                              " "}
                                          </span>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                                <img
                                  className="delete-icon"
                                  src={deleteIcon}
                                  alt="Delete Icon"
                                  onClick={(event) => {
                                    setOpenPopover(true);
                                    setAnchorEl(event.currentTarget);
                                    setPopoverData(item);
                                  }}
                                />
                                <Popover
                                  open={openPopover}
                                  anchorEl={anchorEl}
                                  onClose={(event) => {
                                    setOpenPopover(false);
                                    setAnchorEl(null);
                                    setPopoverData({});
                                  }}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                >
                                  <Box p={2}>
                                    <h3>
                                      Are you sure that you want to delete this
                                      item?
                                    </h3>
                                    <Button
                                      style={{
                                        margin: "10px",
                                        background: "#e93434",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={handleDelete}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      style={{
                                        margin: "10px",
                                        color: "#e93434",
                                        background: "white",
                                        border: "1px solid #e93434",
                                        textTransform: "capitalize",
                                      }}
                                      onClick={(event) => {
                                        setOpenPopover(false);
                                        setAnchorEl(null);
                                        setPopoverData({});
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Box>
                                </Popover>
                              </div>
                            </div>

                            <div className="td price">
                              $
                              {item.price
                                ? item.price?.toFixed(2)
                                : item?.price}
                            </div>
                            <div className="td">
                              {item?.type !== "Tiffin" ? (
                                <div className="qauntity">
                                  <span
                                    className="icon"
                                    onClick={() => {
                                      if (item?.quantity > 1) {
                                        const temp = JSON.parse(
                                          JSON.stringify(data)
                                        );
                                        console.log("temp data:::", temp);
                                        temp[index].quantity =
                                          item?.quantity - 1;
                                        // setData(temp);
                                        console.log(
                                          "set data:::",
                                          temp[index].quantity
                                        );
                                        console.log(
                                          "after click temp data:::",
                                          temp
                                        );
                                        handleQuantity(
                                          item?.product_id,
                                          item?.quantity - 1,
                                          item?.price,
                                          item?.type,
                                          temp
                                        );
                                      }
                                    }}
                                  >
                                    <img src={minusImg} alt="Decreament" />
                                  </span>
                                  <span className="qty">
                                    {item?.type == "Tiffin" ? 1 : item.quantity}
                                  </span>
                                  <span
                                    className="icon"
                                    onClick={() => {
                                      const temp = JSON.parse(
                                        JSON.stringify(data)
                                      );
                                      temp[index].quantity = item?.quantity + 1;
                                      // setData(temp);
                                      handleQuantity(
                                        item?.product_id,
                                        item?.quantity + 1,
                                        item?.price,
                                        item?.type,
                                        temp
                                      );
                                    }}
                                  >
                                    <img src={plusImg} alt="Increament" />
                                  </span>
                                </div>
                              ) : (
                                <div className="qauntityFull">
                                  <span className="qty">
                                    {item?.type === "Tiffin"
                                      ? 1
                                      : item.quantity}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="td total">
                              $ {total?.toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="coupon-wrapper">
                <span>Want to apply promo code?</span>
                <input
                  type="text"
                  placeholder=""
                  name="promocode"
                  onChange={handleChange}
                  value={promocode}
                />
                <button
                  type="button"
                  className="coupon-btn btn"
                  onClick={applyCoupon}
                >
                  Apply Coupon
                </button>
              </div>
              <div className="cart-total">
                <h2>Cart Totals</h2>
                <div className="cart-total-table">
                  {/* <div className="tr">
                <div className="th">Subtotal</div>
                <div className="td">$43.99</div>
              </div> */}
                  {cartTotal.map((item, index) => (
                    <div className="tr" key={index}>
                      <div className="th">{item.name}</div>
                      {item.name === "subtotal" || item.name === "Total" ? (
                        <div className="td">
                          {item.name === "subtotal"
                            ? `$${subTotal?.toFixed(2)} ${promoCodeData?.value
                              ? "(" + promoCodeData?.value + "% DISCOUNT)"
                              : ""
                            }`
                            : "$" + parseFloat(total).toFixed(2)}
                        </div>
                      ) : (
                        <div className="td">
                          {item.shipping ? item.shipping : "$" + item.amount}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <button
                  type="button"
                  className="checkout-btn btn"
                  onClick={handleCheckout}
                >
                  Proceed to Checkout
                </button>
              </div>
              {/* <div className="cart-product-related-card">
          {Array(3)
            .fill("")
            .map((data) => (
              <ProductCard />
            ))}
        </div> */}
            </>
          ) : (
            <>
              <h2 className="text-center">No Cart items!</h2>
              <h4 className="text-center">Please add food items!</h4>
              <button
                className="checkout-btn btn"
                onClick={() => navigate("/tiffinOrdering")}
              >
                Order Now
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
