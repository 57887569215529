const initialValue = {
    user: null,
    userToken: '',
    loading: false,
    error: ''
  };
  
  const userReducer = (state = initialValue, action) => {
    switch (action.type) {
      case "SET_USER_DATA":
        console.log("SET", action.payload)
        return {
          ...state,
          user: action.payload,
          userToken: action.payload.token,
          error: "",
        };
      case "SET_USER_TOKEN":
        return {
          ...state,
          userToken: action.payload,
          error: action.payload,
        };
      case "SET_LOADING":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  