const initialValue = {
  data: [],
  subscription: [],
  addCart: [],
  error: "",
  subTotal: 0,
  total: 0,
};

const cartListReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "GET_CART_TOTAL":
      const internet = (action.payload * 4) / 100;
      const tax = (action.payload * 6.625) / 100;
      return {
        ...state,
        subTotal: action.payload,
        total: action.payload + internet + tax,
        error: "",
      };
    case "ADD_TO_CART":
      return {
        ...state,
        data: [...state?.data, action.payload] ?? [],
        error: "",
      };

    case "REPLACE_CART":
      return {
        ...state,
        data: action.payload ?? [],
        error: "",
      };
    case "GET_CART_DATA_SUCCESS":
      return {
        ...state,
        // data: [...state?.data, ...action.payload],
        data: action.payload?.list ?? [],
        subscription: action.payload?.subsctiption,
        error: "",
      };
    case "GET_CART_DATA_FAILURE":
      return {
        ...state,
        // data: [],
        error: action.payload,
      };

    case "LOGOUT_REQUEST_SUCCESS":
      return {
        ...state,
        data: [],
        addCart: [],
        subscription: [],
      };
    default:
      return state;
  }
};

export default cartListReducer;
