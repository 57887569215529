import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { getLocalState } from "../../common/localstorage";
import { DropdownArrow } from "../../assets/js/DropdownArrow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
  "Pittsburgh",
  "Kentucky",
  "Louisville Kentucky",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const BillingDetail = ({
  billingDetails,
  setBillingDetails,
  isSubscription,
}) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  useEffect(() => {
    setBillingDetails({
      ...billingDetails,
    });
  }, []);

  const handleFormChange = (key, value) => {
    setBillingDetails({
      ...billingDetails,
      [key]: value,
    });
  };

  console.log(billingDetails);
  console.log(getLocalState("selectedStateName"));

  return (
    <Box component="form" noValidate autoComplete="off">
      <div className="form-field">
        <label>First Name *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.firstName}
          onChange={(e) => handleFormChange("firstName", e.target.value)}
          required
        />
      </div>
      <div className="form-field">
        <label>Last Name *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.lastName}
          onChange={(e) => handleFormChange("lastName", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Company Name (Optional)</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.companyName}
          onChange={(e) => handleFormChange("companyName", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Street Address *</label>
        <TextField
          // disabled={isSubscription}
          placeholder="House name and Street name"
          value={billingDetails?.address1}
          onChange={(e) => handleFormChange("address1", e.target.value)}
        />
        <TextField
          // disabled={isSubscription}
          placeholder="Apartment, suit, unit..."
          value={billingDetails?.address2}
          onChange={(e) => handleFormChange("address2", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Town / City *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.city}
          onChange={(e) => handleFormChange("city", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>State *</label>
        <Select
          className="tray-filter-select"
          IconComponent={DropdownArrow}
          defaultValue={0}
          onChange={(e) => handleFormChange("state", e.target.value)}
        >
          <MenuItem value={0}>Select</MenuItem>
          <MenuItem value={'NJ'}>NJ</MenuItem>
          <MenuItem value={'NY'}>NY</MenuItem>
          <MenuItem value={'KY'}>KY</MenuItem>
          <MenuItem value={'PA'}>PA</MenuItem>
          <MenuItem value={'CH'}>CH</MenuItem>
        </Select>
      </div>
      <div className="form-field w-100">
        <label>Postcode / ZIP *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.postCode}
          inputMode={"numeric"}
          onChange={(e) => handleFormChange("postCode", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Phone *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.phone}
          inputMode={"numeric"}
          onChange={(e) => handleFormChange("phone", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Email Address *</label>
        <TextField
          // disabled={isSubscription}
          value={billingDetails?.email}
          inputMode={"email"}
          onChange={(e) => handleFormChange("email", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Order Note (Optional)</label>
        <TextField
          disabled={false}
          multiline
          rows={4}
          placeholder="Notes"
          value={billingDetails?.notes}
          onChange={(e) => handleFormChange("notes", e.target.value)}
        />
      </div>
    </Box>
  );
};

export default BillingDetail;
