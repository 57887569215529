import React from "react";
import cartBanner from "../../assets/cart-banner.png";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./CartBanner.scss";

const CartBanner = () => {
  const windowWidth = useWindowWidth();
  return (
    <div className="cart-banner">
      <img src={cartBanner} alt="Cart Banner" style={{height: windowWidth < 1190 ? 200 : 400 }} />
    </div>
  );
};

export default CartBanner;
