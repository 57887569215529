import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import MainPhoto from "../../assets/ForgotPassword.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { forgotEmail } from "../../redux/actions/auth";

import "./forgotpassword.scss";
import { CONSTANTS } from "../../common/constants";
import { getLocalState } from "../../common/localstorage";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email(CONSTANTS.NOT_VALID_EMAIL)
      .required(CONSTANTS.REQUIRED_FIELD),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(forgotEmail(values, navigate));
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (getLocalState("token")) {
  //     navigate(-1);
  //   }
  // }, []);
  return (
    <div className="forgot-password-page">
      <Avatar
        className="forgot-password-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        sx={{ width: 76, height: 76 }}
        onClick={() => navigate("/splash")}
      />
      <div className="forgot-password-page-container">
        <div className="forgot-password-page-leftbox">
          <img
            src={MainPhoto}
            alt="forgot-password Page"
            className="forgot-password-page-photo"
          />
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="forgot-password-page-rightbox"
        >
          <h1 className="forgot-password-page-right-title">Forgot Password?</h1>
          <h5 className="forgot-password-page-right-subtitle">
            Enter your email address to a password reset. You will receive an
            email containing a reset password instruction.
          </h5>
          <div className="forgot-password-page-right-email-container">
            <h3 className="forgot-password-page-right-email-label">
              Email Address
            </h3>
            <TextField
              className="forgot-password-page-right-email-input"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <Button type="submit" className="forgot-password-page-right-button">
            Submit
          </Button>
          <div className="forgot-password-page-remember-password">
            Remember Password{" "}
            <span>
              <Link to={"/signin"}>Back to Sign in</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
