const initialValue = {
  galleryData: [],
  error: "",
};

const galleryDataReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "GET_GALLERY_DATA_SUCCESS":
      return {
        ...state,
        galleryData: action.payload.data,
        error: "",
      };
    case "GET_GALLERY_DATA_FAILURE":
      return {
        ...state,
        galleryData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default galleryDataReducer;
