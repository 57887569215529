import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { endpoints } from "../../redux/endpoint";
import "./MyOrder.scss";
import CartBanner from "../../components/CartBanner/CartBanner";
import CircularProgress from "@mui/material/CircularProgress";
import foodItem from "../../assets/food-img.png";
import { useDispatch, useSelector } from "react-redux";
import { orderListData, getOrderDataFailure, getOrderDataSuccess } from "../../redux/orderList/action/orderList.action";
import moment from "moment";
import { getLocalState } from "../../common/localstorage";
import { useNavigate } from "react-router-dom";

const MyOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true)
  useEffect(() => {
    axios
      .get(endpoints.orderList)
      .then((res) => {
        if (res.data.code === 1) {
          console.log("response data::::", res)
          // NotificationManager.success(res.data.message);
          dispatch(getOrderDataSuccess(res.data.data));
        } else {
          // NotificationManager.error(res.data.message);
        }
        setLoader(false)
      })
      .catch((err) => {
        // NotificationManager.error(err.message);
        dispatch(getOrderDataFailure(err.message));
        // setLoader(false)
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  const orderData = useSelector((state) => state?.orderListReducer?.data);
  console.log("order list data::::", orderData);

  return (
    <div className="my-order-wrapper">
      <CartBanner />
      <div className="order-main-wrapper">
        <h2 className="title">My Orders</h2>
        <div
          className="loading-backdrop"
          style={{
            display: loader ? "flex" : "none"
          }}
        >
          <CircularProgress color="warning" />
        </div>
        {orderData?.length > 0 ? (
          <>
            <div className="order-main">
              {/* <h3>Past Orders</h3> */}
              <div className="order-list">
                {orderData?.map((data, index) =>
                  data?.detail?.map((item, index) => {
                    const myArray = item?.tiffin_item?.split(",");
                    const final = myArray?.reverse();
                    return (
                      <>
                        <div className="order-item">
                          <div className="order-img">
                            <img src={item?.image} alt="Food Item" />
                          </div>
                          <div className="order-detail">
                            <div className="title">{item?.name}</div>
                            <div className="order-number">
                              Order No. #{item?.id}
                            </div>
                            {final?.map((j) => (
                              <div className="delivery-date">{j}</div>
                            ))}
                            <div className="delivery-date">
                              Delivered on{" "}
                              {moment(
                                new Date(parseInt(item?.insert_datetime) * 1000)
                              ).format("llll")}
                              {/* {item.deliveryDate}, {item.deliveryTime} */}
                            </div>
                          </div>
                          <div className="price-detail">
                            <span className="amount">${item?.price}</span>
                            {/* <span className="payment-situation">{item.payment}</span> */}
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </>
        ) : (
          <p className="text-center">NO ORDERS AVAILABLE</p>
        )}
      </div>
    </div>
  );
};

export default MyOrder;
