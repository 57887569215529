const initialValue = {
  slider: [],
  gallery: [],
  menu: [],
  error: "",
  isDone: false,
  sheetyMenu: [],
};

const homeDataReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "SET_IS_DONE":
      return {
        ...state,
        isDone: false,
      };
    case "GET_HOME_DATA_SUCCESS":
      return {
        ...state,
        slider: action.payload.slider.reverse(),
        gallery: action.payload.gallery,
        menu: action.payload.menu,
        error: "",
        isDone: true,
      };
    case "GET_HOME_DATA_FAILURE":
      return {
        ...state,
        homeData: [],
        error: action.payload,
        isDone: true,
      };
    case "SHEETY_MENU_DATA_SUCCESS":
      return {
        ...state,
        sheetyMenu: action.payload,
        error: "",
        isDone: true,
      };
    case "SHEETY_MENU_DATA_FAILURE":
      return {
        ...state,
        sheetyMenu: [],
        error: action.payload,
        isDone: true,
      };
    default:
      return state;
  }
};

export default homeDataReducer;
