import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLocalState } from "../../common/localstorage";
import CartBanner from "../../components/CartBanner/CartBanner";
import { mySubscription } from "../../redux/mySubscription/action/mySubscription.action";
import "./MySubscription.scss";

const MySubscription = () => {
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionList = useSelector(
    (state) => state?.mySubscriptionReducer?.data
  );
  console.log("subscription list:::", subscriptionList);

  useEffect(() => {
    dispatch(mySubscription());
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  return (
    <div className="my-subscription-wrapper">
      <CartBanner />
      <div className="subscription-main-wrapper">
        <h2 className="title">My Subscription</h2>
        {subscriptionList?.length > 0 ? (
          subscriptionList?.map((item) => {
            console.log("ITEM", item);
            const date = Date(item?.item?.insert_datetime);
            const formattedDate = new Date(date).toLocaleDateString();
            const temp = item?.use_quantity;
            const filled = item?.quantity - temp;
            const unfilled = filled > 0 ? temp : 0;
            return (
              <>
                <div className="subscription-main">
                  <h3></h3>
                  <div className="subscription-list">
                    <div className="subscription-item">
                      <div className="left">
                        <div className="title">{item?.name}</div>
                        <div className="sub-title">{item?.description}</div>
                        {filled > 0 ? (
                          <ul>
                            {Array(filled)
                              .fill()
                              .map((i) => (
                                <li className="active">
                                  <span></span>
                                </li>
                              ))}
                            {Array(unfilled)
                              .fill()
                              .map((i) => (
                                <li className="inactive">
                                  <span></span>
                                </li>
                              ))}
                          </ul>
                        ) : (
                          <div className="" style={{ color: "red" }}>
                            {"Your subscription has been completed!"}
                          </div>
                        )}
                      </div>
                      <div className="right">
                        <div className="purchase-date">
                          Purchase on: {formattedDate}
                        </div>
                        <div className="amount">
                          $
                          {item?.price ? (item?.price).toFixed(2) : item?.price}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <div>
            <p className="text-center">NO SUBSCRIPTION AVAILABLE</p>
            <Button
              className="place-order-button"
              onClick={() => navigate("/subscription")}
            >
              Buy Monthly Subscription
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MySubscription;
