import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getCartDataSuccess = (item) => {
  console.log("CART ACT", item);
  return {
    type: "GET_CART_DATA_SUCCESS",
    payload: item,
  };
};

export const getCartDataFailure = (error) => {
  return {
    type: "GET_CART_DATA_FAILURE",
    payload: error,
  };
};

export const getCartTotal = (total) => {
  return {
    type: "GET_CART_TOTAL",
    payload: total,
  };
};

export const cartList = () => (dispatch) => {
  axios
    .post(endpoints.cartList)
    .then((res) => {
      const parsed = JSON.parse(JSON.stringify(res));
      console.log("MAIN RESPONSE", res, parsed.data);
      if (parsed.data.code == 1) {
        console.log("INSIDE", parsed.data.data ?? []);
        dispatch({
          type: "GET_CART_DATA_SUCCESS",
          payload: parsed.data.data ?? [],
        });
      } else if (parsed.data.code == 2) {
        dispatch(getCartDataSuccess([]));
      } else {
        // NotificationManager.error(parsed.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getCartDataFailure(err.message));
    });
};

// export const deleteCartList = (id) => {
//   return (dispatch) => {
//     dispatch({
//       type: "DELETE_CART_ITEM_REQUEST",
//     });
//     return deleteData(id)
//       .then((res) => {
//         console.log("response",res)
//         dispatch({
//           type: "DELETE_CART_ITEM_SUCCESS",
//           payload: res,
//           data: id,
//         });
//         dispatch(cartList());
//       })
//       .catch((err) => {
//         dispatch({
//           type: "DELETE_CART_ITEM_FAILURE",
//         });
//       });
//   };
// };
