export const RedTriangle = (props) => (
  <svg
    width="154"
    height="175"
    viewBox="0 0 154 175"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M153.258 84.4734L4.54995 174.953L0.546788 0.927724L153.258 84.4734Z"
      fill="#F8D1A5"
      {...props}
    />
  </svg>
);
