import { Avatar, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MainPhoto from "../../assets/NonAuthImage.png";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./otpverification.scss";
import { CONSTANTS } from "../../common/constants";
import { useDispatch } from "react-redux";
import { forgotEmail, verifyOtp } from "../../redux/actions/auth";

const OtpVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const validationSchema = Yup.object({
    otpverification: Yup.string()
      .matches(/^[0-9]{4}$/, CONSTANTS.OTP_LENGTH)
      .required(CONSTANTS.REQUIRED_FIELD),
  });

  const formik = useFormik({
    initialValues: {
      otpverification: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        verifyOtp(
          {
            email: location?.state?.email,
            otp: values?.otpverification,
          },
          navigate
        )
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (location?.state === {} || !location?.state?.email) {
    navigate("/forgotpassword");
  }

  return (
    <div className="otp-verification-page">
      <Avatar
        className="otp-verification-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        sx={{ width: 76, height: 76 }}
        onClick={() => navigate("/splash")}
      />
      <div className="otp-page-container">
        <div className="otp-verification-page-leftbox">
          <img
            src={MainPhoto}
            alt="otp-verification Page"
            className="otp-verification-page-photo"
          />

          <div className="otp-verification-page-left-center">
            <h2 className="otp-verification-page-left-title">
              OTP Verification
            </h2>
            <h5 className="otp-verification-page-left-subtitle">
              Sign In by clicking the button below
            </h5>
            <Button
              className="otp-verification-page-left-button"
              onClick={() => navigate("/signin")}
            >
              Sign In
            </Button>
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="otp-verification-page-rightbox"
        >
          <h1 className="otp-verification-page-right-title">
            OTP Verification
          </h1>
          <h5 className="otp-verification-page-right-subtitle">
            Please enter the OTP to verify the email which you received in the
            entered email id.
          </h5>
          <div className="otp-verification-page-right-otp-container">
            <h3 className="otp-verification-page-right-otp-label">OTP</h3>
            <TextField
              className="otp-verification-page-right-otp-input"
              type="number"
              name="otpverification"
              variant="outlined"
              value={formik.values.otpverification}
              onChange={formik.handleChange}
              error={
                formik.touched.otpverification &&
                Boolean(formik.errors.otpverification)
              }
              helperText={
                formik.touched.otpverification && formik.errors.otpverification
              }
            />
          </div>
          <p
            className="otp-verification-page-right-resend-code"
            onClick={() => {
              dispatch(
                forgotEmail({
                  email: location?.state?.email,
                })
              );
            }}
          >
            Resend Code
          </p>
          <Button type="submit" className="otp-verification-page-right-button">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
