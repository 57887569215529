import React, { useEffect, useState } from "react";
import "./card.scss";

const Card = ({ name, data }) => {
  const menuData = data;
  const [menuLength, setMenuLength] = useState(0);

  useEffect(() => {
    if (
      menuData[0].menu.length >
      menuData[1].menu.length >
      menuData[2].menu.length
    ) {
      setMenuLength(menuData[0].menu.length);
    } else if (
      menuData[0].menu.length <
      menuData[1].menu.length >
      menuData[2].menu.length
    ) {
      setMenuLength(menuData[1].menu.length);
    } else if (
      menuData[0].menu.length <
      menuData[1].menu.length <
      menuData[2].menu.length
    ) {
      setMenuLength(menuData[2].menu.length);
    }
  }, []);
  return (
    <div className="card">
      <h5 className="card-title">{name}</h5>
      <div className="card-calender">
        <div className="card-day">
          {menuData?.map((j, i) => (
            <div
              className="card-day-title"
              style={{
                background: i % 2 !== 0 && "#292929",
                color: i % 2 !== 0 && "#fff",
              }}
            >
              {j?.day}
            </div>
          ))}
          {Array(menuLength)
            .fill("")
            .map((_, i) => (
              <>
                <div className="card-dishes">
                  {menuData[0].menu[i]?.name ? menuData[0].menu[i]?.name : "-"}
                </div>
                <div
                  className="card-dishes"
                  style={{
                    background: "#292929",
                    color: "#fff",
                  }}
                >
                  {menuData[1].menu[i]?.name ? menuData[1].menu[i]?.name : "-"}
                </div>
                <div className="card-dishes">
                  {menuData[2].menu[i]?.name ? menuData[2].menu[i]?.name : "-"}
                </div>
              </>
            ))}
          {/* {j?.menu.map((i) => (
              <div className="card-dishes">{i.name ? i.name : "-"}</div>
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default Card;
