import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "../../axios";
import { endpoints } from "../../redux/endpoint";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { lowerCase } from "lodash";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SplashMainImage from "../../assets/Splash-Screen.png";
import { getLocalState } from "../../common/localstorage";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  addCart,
  addToCart,
} from "../../redux/addToCart/actions/addToCart.action";
import { getDataFailure, getDataSuccess } from "../../redux/tiffinCategory/action/tiffinCategory.action";
import { getAddToCartFailure, getAddToCartSuccess } from "../../redux/addToCart/actions/addToCart.action";
import { cartList } from "../../redux/cartList/actions/cartList.action";
import "./productSinglePage.scss";

const chooseData = [
  {
    name: "PANNER DUM BIRYANI",
  },
  {
    name: "Roti 10 Pcs",
  },
  {
    name: "PAU BHAJI",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ProductSinglePage = () => {
  const token = getLocalState("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(3);
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemId, setItemId] = useState([]);
  const [extraItem, setExtraItem] = useState([]);
  const [total, setTotal] = useState(0);
  const [allItemsCategory, setAllItemsCategory] = useState([]);

  const itemCategory = useSelector(
    (state) => state?.tiffinCategoryReducer?.data
  );
  const item = useSelector((state) => state?.tiffinListReducer?.selectedTiffin);
  const stateData = useSelector((state) => state.stateListReducer);
  const totalPrice = total + item?.price;
  const [isNonVeg, setIsNonVeg] = useState(
    lowerCase(item.title).includes("non veg")
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .post(endpoints.tiffinCategory,
        {
          tiffin_id: item?.id,
        })
      .then((res) => {
        if (res.data.code === 1) {
          // NotificationManager.success(res.data.message);
          dispatch(getDataSuccess(res.data.data));
        } else {
          // NotificationManager.error(res.data.message);
        }
        setLoading(false)
      })
      .catch((err) => {
        NotificationManager.error(err.message);
        dispatch(getDataFailure(err.message));
        setLoading(false)
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  useEffect(() => {
    if (!Array.isArray(itemCategory)) {
      const sortedCategoryArr = [
        ...itemCategory?.category?.filter((d) => d?.quantity === 1),
        ...itemCategory?.category?.filter(
          (d) => d?.quantity !== 1 && d?.quantity !== 0
        ),
        ...itemCategory?.category?.filter((d) => d?.quantity === 0),
      ];
      const addedQuantityItems = sortedCategoryArr?.map((category, i) => ({
        ...category,
        item: category?.item?.map((dish, j) => ({
          ...dish,
          quantity: category.quantity === 1 && i === 0 && j === 0 ? 1 : 0,
        })),
      }));
      setAllItemsCategory(addedQuantityItems);
    }
  }, [itemCategory]);

  const handleAddToCart = () => {
    if (selectedDataPrice() + (allItemsCategory?.filter((ele) => ele.quantity > 0).length ? item?.price : 0) >= item.minOrderPrice) {
      const newItemCategory = [...allItemsCategory];
      const allItemsArr = newItemCategory.map((d) => d?.item);
      const data = {
        state_id: stateData?.selectedState ?? 1,
        quantity: "1",
        price: selectedDataPrice() + (allItemsCategory?.filter((ele) => ele.quantity > 0).length ? item?.price : 0),
        type: "Tiffin",
        tiffin_id: item?.id ?? 1,
        tiffin_detail_id: allItemsArr
          .flat()
          ?.filter((d) => d?.quantity > 0)
          ?.map((d) => ({
            detail_id: d?.id,
            quantity: d?.quantity,
          })),
      };
      if (token) {
        setLoading(true)
        axios
          .post(endpoints.addToCart, data)
          .then((res) => {
            if (res.data.code === 1) {
              dispatch(getAddToCartSuccess(res.data));
              // dispatch(addCart(res.data))
              dispatch(cartList());
              navigate('/cart')
              NotificationManager.success(res.data.message);
            } else {
              // NotificationManager.error(res.data.message);
            }
          })
          .catch((err) => {
            NotificationManager.error(err.message);
            dispatch(getAddToCartFailure(err.message));
          });
      } else {
        NotificationManager.error("Please login to continue");
        localStorage.setItem('cartRoute', window.location.pathname)
        navigate('/signin')
        // data.name = item?.title;
        // data.description = item?.description;
        // data.tiffin_detail_id = allItemsArr
        //   .flat()
        //   ?.filter((d) => d?.quantity > 0)
        //   ?.map((d) => ({
        //     id: item?.id,
        //     quantity: d?.quantity,
        //     descripion: d?.name,
        //     price: d?.price,
        //     tiffin_detail_id: d?.id,
        //   }));
        // dispatch(addCart(data, navigate));
      }
      // navigate("/cart");
    } else {
      NotificationManager.error(`Order price should be greater than ${item.minOrderPrice}`);
    }
  };

  const checkButtonDisabled = () => {
    if (allItemsCategory.filter((ele) => ele?.quantity > 0).length) {
      const newItemCategory = [...allItemsCategory];
      const allItemsArr = newItemCategory.map(
        (d) => d?.quantity !== 0 && d?.quantity !== 1 && d?.item
      );
      const mainQuantity = newItemCategory
        .map((d) => d?.quantity !== 0 && d?.quantity !== 1 && d?.quantity)
        .filter((d) => !!d);
      return !(
        allItemsArr
          .flat()
          ?.filter((d) => d?.quantity > 0)
          ?.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue?.quantity,
            0
          ) === mainQuantity[0]
      );
    } else return false
  }

  const handleQuantityIncrease = (data, item) => {
    const newItemCategoryData = [...allItemsCategory];
    const itemIndex = newItemCategoryData?.findIndex((n) => n?.id === item.id);
    if (
      !(
        item?.quantity !== 0 &&
        item?.quantity !== 1 &&
        checkTotalQuantity(itemIndex) === item?.quantity
      )
    ) {
      const dataIndex = newItemCategoryData[itemIndex]?.item?.findIndex(
        (d) => d?.id === data.id
      );
      const updatedData = {
        ...newItemCategoryData[itemIndex]?.item[dataIndex],
        quantity: newItemCategoryData[itemIndex]?.item[dataIndex]?.quantity + 1,
      };
      newItemCategoryData[itemIndex]?.item?.splice(dataIndex, 1, updatedData);
      setAllItemsCategory(newItemCategoryData);
    }
  };

  const handleQuantityDecrease = (data, item) => {
    const newItemCategoryData = [...allItemsCategory];
    const itemIndex = newItemCategoryData?.findIndex((n) => n?.id === item.id);
    const dataIndex = newItemCategoryData[itemIndex]?.item?.findIndex(
      (d) => d?.id === data.id
    );
    const updatedData = {
      ...newItemCategoryData[itemIndex]?.item[dataIndex],
      quantity:
        newItemCategoryData[itemIndex]?.item[dataIndex]?.quantity === 0
          ? newItemCategoryData[itemIndex]?.item[dataIndex]?.quantity
          : newItemCategoryData[itemIndex]?.item[dataIndex]?.quantity - 1,
    };
    newItemCategoryData[itemIndex]?.item?.splice(dataIndex, 1, updatedData);
    setAllItemsCategory(newItemCategoryData);
  };

  const handleOnChange = (event, data, item) => {
    const newItemCategoryData = [...allItemsCategory];
    const itemIndex = newItemCategoryData?.findIndex((n) => n?.id === item.id);
    const dataIndex = newItemCategoryData[itemIndex]?.item?.findIndex(
      (d) => d?.id === data.id
    );
    const updatedData = {
      ...newItemCategoryData[itemIndex]?.item[dataIndex],
      quantity: event.target.checked ? 1 : 0,
    };
    newItemCategoryData[itemIndex]?.item?.splice(dataIndex, 1, updatedData);
    setAllItemsCategory(newItemCategoryData);
  };

  const checkTotalQuantity = (itemIndex) => {
    const newItemCategory = [...allItemsCategory];
    return newItemCategory[itemIndex]?.item?.reduce(
      (previousValue, currentValue) => previousValue + currentValue?.quantity,
      0
    );
  };

  const selectedDataPrice = () => {
    if (allItemsCategory) {
      const newItemCategory = [...allItemsCategory];
      const allItemsArr = newItemCategory.map(
        (d) => d?.quantity === 0 && d?.item
      );
      return allItemsArr
        .flat()
        ?.filter((d) => d?.quantity > 0)
        ?.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue?.price * currentValue?.quantity,
          0
        );
    }
  };

  const handleRadioGroupChange = (id, item) => {
    const newItemCategoryData = [...allItemsCategory];
    const itemIndex = newItemCategoryData?.findIndex((n) => n?.id === item.id);
    const updatedItem = newItemCategoryData[itemIndex]?.item?.map((d) => ({
      ...d,
      quantity: d?.id == id ? 1 : 0,
    }));
    newItemCategoryData[itemIndex] = {
      ...newItemCategoryData[itemIndex],
      item: updatedItem,
    };
    setAllItemsCategory(newItemCategoryData);
  };

  if (!item?.id) {
    navigate("/tiffinordering");
  }

  return (
    <div className="product-single-page">
      <div
        className="loading-backdrop"
        style={{
          display: loading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      {/* <div className="product-single-page-image-container">
        <img src={MainImage} alt="main" style={{height: windowWidth < 1190 ? 200 : 400}} />
        <div className="main-image-text">
          <h3>Tiffin Mom's</h3>
          <h1>Weekday Tiffin Service</h1>
        </div>
      </div> */}
      <div className="product-single-page-container">
        <div className="tiffin-ordering-card">
          <div className="image-section">
            <img
              src={item?.image || SplashMainImage}
              alt="tiffin-ordering-card"
            />
          </div>
          <div className="details-section">
            <h3 className="detail-title">{item?.title}</h3>
            <div className="detail-deliver-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.delivery_on,
                  // .replace(
                  //   /(<? *script)/gi,
                  //   "illegalscript"
                  // ),
                }}
              ></div>
            </div>
            {/* <div className="detail-deliver-address-1 detail-deliver-address">
              {item?.delivery_on}
            </div> */}
            <div className="detail-deliver-address-2 detail-deliver-address">
              Price - ${item?.price} for each Tiffin
            </div>
            <div className="detail-deliver-address-3 detail-deliver-address">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                  // .replace(
                  //   /(<? *script)/gi,
                  //   "illegalscript"
                  // ),
                }}
              ></div>
            </div>
          </div>
        </div>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" color="error" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Choose an option</Typography>
          </AccordionSummary>
          {chooseData?.map((data) => {
            return (
              <>
                <AccordionDetails>
                  <FormGroup className="accordion-details-container">
                    <FormControlLabel
                      control={<Checkbox />}
                      label={data?.name} />
                  </FormGroup>
                </AccordionDetails>
              </>

            )
          })}

        </Accordion> */}

        <div className="product-single-page-accordion-container">
          {allItemsCategory?.map((item, itemIndex) => {
            return (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon fontSize="large" color="error" />
                    }
                    id="accordion-header"
                    className="accordion-header"
                  >
                    <Typography>{item?.name}</Typography>
                    <div className="chip-container">
                      {item?.id === 1 || item?.id === 14
                        ? selectedItem?.map((item) => {
                          return (
                            <>
                              {Array(1)
                                .fill("")
                                .map(() => (
                                  <Chip label={item?.name} />
                                ))}
                            </>
                          );
                        })
                        : ""}
                    </div>
                  </AccordionSummary>
                  {item?.quantity === 1 ? (
                    <FormControl className="accordion-detail-1">
                      <RadioGroup
                        name="free-only-one"
                        className="free-only-one"
                        onChange={(e) =>
                          handleRadioGroupChange(e.target.value, item)
                        }
                      >
                        {item?.item?.map((data, dataIndex) => (
                          <FormControlLabel
                            className="radio-btn"
                            key={dataIndex}
                            value={data?.id}
                            control={<Radio checked={data?.quantity === 1} />}
                            label={data?.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <AccordionDetails className="accordion-details">
                      {item?.item?.map((data, dataIndex) => {
                        let label = `${data.name} `;
                        if (item?.quantity === 0) {
                          label = `${data.name} (+$${data?.price})`;
                        }
                        return (
                          <>
                            <FormGroup className="accordion-details-container">
                              <>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        handleOnChange(e, data, item);
                                      }}
                                      checked={data?.quantity > 0}
                                      disabled={
                                        (item?.quantity === 3 ||
                                          item?.quantity === 7 || item?.quantity === 4) &&
                                        !(data?.quantity > 0) &&
                                        checkTotalQuantity(itemIndex) ===
                                        item?.quantity
                                      }
                                    />
                                  }
                                  label={label}
                                />
                              </>
                              <div className="product-detail-cart">
                                <div className="product-detail-numbers">
                                  <RemoveIcon
                                    className="product-minus product-btn"
                                    onClick={() =>
                                      handleQuantityDecrease(data, item)
                                    }
                                  />
                                  <div className="product-number">
                                    {data?.quantity}
                                  </div>
                                  <AddIcon
                                    className="product-plus product-btn"
                                    onClick={() =>
                                      handleQuantityIncrease(data, item)
                                    }
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </>
                        );
                      })}
                    </AccordionDetails>
                  )}
                </Accordion>
              </>
            );
          })}
        </div>

        <div>
          <h3 className="detail-title">
            Tiffin price :{" "}
            {"$" + (isNonVeg ? 0 : (item?.price)?.toFixed(2) ?? 0)}
          </h3>
          <h3 className="detail-title">
            Extra price : {"$" + (selectedDataPrice()).toFixed(2)}
          </h3>
          <h3 className="detail-title">
            Total Price :{" "}
            {console.log(isNonVeg
              , selectedDataPrice().toFixed(2)
              , (selectedDataPrice() + item?.price).toFixed(2) ?? 0)}
            {"$" +
              (isNonVeg
                ? selectedDataPrice().toFixed(2)
                : (selectedDataPrice() + item?.price).toFixed(2) ?? 0)}
          </h3>
        </div>
        <br />
        <Button
          className="add-to-cart-btn"
          onClick={() => {
            if (checkButtonDisabled()) {
              setModalOpen(true);
            } else {
              handleAddToCart();
            }
          }}
        >
          Add to Cart
        </Button>
      </div>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" as="h2">
            Please Select all the Required Dishes, Then you can continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProductSinglePage;
