import React from "react";
import { AiFillInstagram, AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-links">
        {/* <a className="footer-link" href="www.google.com">
          About Us
        </a> */}
        <a className="footer-link" href="www.google.com">
          Terms & Conditions
        </a>
        <a className="footer-link" href="www.google.com">
          Privacy Policy
        </a>

        <Link to={"/howitsworks"}>
          <span className="footer-link">How it Works</span>
        </Link>

        <Link to={"/faq"}>
          <span className="footer-link">FAQs</span>
        </Link>
      </div>
      <div className="footer-social-icons">
        <BsFacebook color="#E93434" />
        <AiFillTwitterCircle color="#E93434" />
        <AiFillInstagram color="#E93434" />
        <AiFillLinkedin color="#E93434" />
      </div>
      <div className="footer-detail">
        Food Allergen Labeling and Consumer Protection Act (FALCPA)<br/>
        Our food is prepared in a facility which also processes  dairy <br/>products  like milk, eggs, fish, crustacean<br/>
        shellfish, peanuts, cashew nuts tree nuts, wheat and soy.<br/><br/>
        2022 by Tiffin Mom | All Rights Reserved<br/>
      </div>
    </div>
  );
};

export default Footer;
