import React, { useEffect } from "react";
import "./productDetail.scss";
import MainImage from "../../assets/TrayMenu Image.png";
import { Button, MenuItem, Rating, Select } from "@mui/material";
import { DropdownArrow } from "../../assets/js/DropdownArrow";
import { BsFacebook } from "react-icons/bs";
import {
  AiFillTwitterCircle,
  AiFillInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import ProductCard from "../../components/ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import { getLocalState } from "../../common/localstorage";

const ProductDetail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  // useEffect(() => {
  //   if (!getLocalState("token")) {
  //     navigate("/signin");
  //   }
  // }, []);
  return (
    <div className="product-detail">
      <img src={MainImage} className="product-detail-image" />
      <div className="product-detail-container">
        <h1 className="product-detail-title">Aloo Paratha (30 Pcs.)</h1>
        <div className="product-detail-rating">
          <div className="rating-number">4.7</div>
          <Rating defaultValue={5} precision={0.5} readOnly />
        </div>
        <p className="product-detail-desc">
          Lörem ipsum sofäbelt tiren i prede gyse. Pode Foppatoffel, i satt.
          Kanera antirat fövinde fast trevask. Mikys pseudoråst käbesa milig.
          Teleception tid emedan bähår. Prekrokyngar ryn utan trin. Vis nylur,
          onat. Nyns naling kontraligt.{" "}
        </p>
        <div className="product-detail-category">
          <b>Category: </b>
          North Indian, Punjabi Appetizer
        </div>
        <div className="product-detail-price">$ 50.00</div>
        <div className="product-detail-options">
          <div className="product-detail-option">
            <Select
              className="product-detail-select"
              defaultValue={""}
              IconComponent={(props) => (
                <DropdownArrow {...props} fill="#e93434" />
              )}
              displayEmpty
            >
              <MenuItem disabled hidden value="">
                <em>Select an Option</em>
              </MenuItem>
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"All1"}>All1</MenuItem>
              <MenuItem value={"All2"}>All2</MenuItem>
            </Select>
            <div className="product-detail-cart">
              <div className="product-detail-numbers">
                <div className="product-minus product-btn"> - </div>
                <div className="product-number">2</div>
                <div className="product-plus product-btn"> + </div>
              </div>
              <Button className="product-detail-add-to-cart">
                Add to Cart
              </Button>
            </div>
          </div>
          <div className="product-detail-social-media">
            <BsFacebook />
            <AiFillTwitterCircle />
            <AiFillInstagram />
            <AiFillLinkedin />
          </div>
        </div>
        <div className="product-detail-related-products">
          <h1>Related Products</h1>
          <div className="product-detail-related-products-cards">
            {Array(3)
              .fill("")
              .map((data) => (
                <ProductCard />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
