import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import "./ComingSoon.scss";

const ComingSoon = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="coming-soon-page-wrapper">
      <div className="content-block">
        <Loader />
        <h1 className="title">Coming Soon!</h1>
        <p>Join us as we build Tiffin Mom.</p>
        <span>Sign up For Your Early Access Invite</span>
        <div className="field-area">
          <input type="email" name="email" placeholder="Type Your Email" />
          <button>Notify Me</button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
