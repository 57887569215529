import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainGalleryImage from "../../assets/GalleryPage.png";
import { getLocalState } from "../../common/localstorage";
import TabPanel from "../../common/TabPanel";
import GalleryImagesGrid from "../../components/GalleryImagesGrid/GalleryImagesGrid";
import useWindowWidth from "../../hooks/useWindowWidth";
import { galleryData } from "../../redux/gallery/actions/gallery.actions";
import "./gallery.scss";

const Gallery = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [imageNo, setImageNo] = useState(9);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const windowWidth = useWindowWidth();

  const stateData = useSelector((state) => state.stateListReducer);
  const galleryImages = useSelector(
    (state) => state.galleryDataReducer.galleryData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  const handleChange = (event, newValue) => {
    // setSelectedTab(newValue);
  };

  useEffect(() => {
    let data = {
      state_id: stateData?.selectedState,
      page: 0,
    };

    dispatch(galleryData(data));
  }, [stateData?.selectedState]);

  return (
    <>
      <div className="gallery">
        <div className="gallery-main-image">
          <img
            src={MainGalleryImage}
            alt="main-gallery-image"
            style={{ height: windowWidth < 1190 ? 200 : 400 }}
          />
        </div>
        <h3 className="gallery-quote">
          “Pull up a chair. Take a taste. Come join us. Life is so endlessly
          delicious.”
        </h3>
        <div className="gallery-tabs-container">
          {/* <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab label="All" value={0} />
            <Tab label="Punjabi" value={1} />
            <Tab label="Gujarati" value={2} />
            <Tab label="South Indian" value={3} />
            <Tab label="Chinese" value={4} />
          </Tabs> */}
          {galleryImages.length > 0 &&
            Array(5)
              .fill("")
              .map((_, i) => (
                <TabPanel value={selectedTab} index={i}>
                  <GalleryImagesGrid
                    imageList={galleryImages}
                    selectedTab={selectedTab}
                  // imageNo={galleryImages.length}
                  />
                </TabPanel>
              ))}
        </div>
        {/* <Button className="gallery-btn" onClick={() => setImageNo(imageNo + 3)}>
          See More
        </Button> */}
      </div>
    </>
  );
};

export default Gallery;
