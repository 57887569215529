import axios from "axios";

const token = localStorage.getItem("userToken");

export const baseURL = "https://api1.tiffinmom.com/api/website/v1/";
// export const baseURL = "http://localhost:8426/api/website/v1/"
const axiosInstance = axios.create({
  // baseURL: "https://api.tiffinmom-cran.com/api/website/v1/",
  baseURL,
  headers: {
    Authorization: !!token ? "Bearer " + localStorage.getItem("userToken") : null,
    token: localStorage.getItem("userToken"),
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong!"
    )
);

export default axiosInstance;
