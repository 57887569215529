import React, { useEffect } from "react";
import "./signin.scss";
import {
  Avatar,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../../redux/actions/auth";
import { CONSTANTS } from "../../common/constants";
import { getLocalState } from "../../common/localstorage";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(CONSTANTS.NOT_VALID_EMAIL)
      .required(CONSTANTS.REQUIRED_FIELD),
    password: Yup.string()
      .min(8, CONSTANTS.PASSWORD_MIN_8)
      .required(CONSTANTS.REQUIRED_FIELD),
    rememberme: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberme: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(login(values, navigate));
    },
  });

  // useEffect(() => {
  //   if (getLocalState("token")) {
  //     navigate(-1);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="signin-page">
      <Avatar
        className="sign-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        onClick={() => navigate("/splash")}
        sx={{ width: 76, height: 76 }}
      />
      <div className="signin-page-container">
        <div className="signin-page-leftbox">
          <div className="signin-page-left-center">
            <h2 className="signin-page-left-title">Don't Have an account?</h2>
            <h5 className="signin-page-left-subtitle">
              Sign up by clicking the button below
            </h5>
            <Button
              className="signin-page-left-button"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </Button>
          </div>
        </div>
        <form className="signin-page-rightbox" onSubmit={formik.handleSubmit}>
          <h1 className="signin-page-right-title">Sign In</h1>
          <div className="signin-page-right-email-container">
            <h3 className="signin-page-right-email-label">Email Address</h3>
            <TextField
              className="signin-page-right-email-input"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="signin-page-right-password-container">
            <h3 className="signin-page-right-password-label">Password</h3>
            <TextField
              type="password"
              className="signin-page-right-password-input"
              name="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          <div className="signin-page-rememberme-container">
            <FormControlLabel
              onChange={formik.handleChange}
              error={
                formik.touched.rememberme && Boolean(formik.errors.rememberme)
              }
              label="Remember me"
              name="rememberme"
              control={<Checkbox checked={formik.values.rememberme} />}
            />
            <Link to={"/forgotpassword"}>Forgot Password?</Link>
          </div>
          <Button type="submit" className="signin-page-right-button">
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
