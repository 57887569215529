export const GreenTriangle = (props) => (
  <svg
    width="127"
    height="126"
    viewBox="0 0 127 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M126.801 0.480411L95.1605 125.897L0.0610109 26.3136L126.801 0.480411Z"
      fill="#B5EE9E"
      {...props}
    />
  </svg>
);
