import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { headerConfig } from "../../../common/headerConfig";
import { getLocalState } from "../../../common/localstorage";
import { endpoints } from "../../endpoint";

export const editSuccess = (item) => {
  return {
    type: "EDIT_SUCCESS",
    payload: item,
  };
};

export const editFailure = (error) => {
  return {
    type: "EDIT_FAILURE",
    payload: error,
  };
};

export const getSuccess = (item) => {
  return {
    type: "GET_SUCCESS",
    payload: item,
  };
};

export const getFailure = (error) => {
  return {
    type: "GET_FAILURE",
    payload: error,
  };
};

export const getProfile = () => (dispatch) => {
  axios
    .post(
      endpoints.getProfile,
      {
        user_id: getLocalState("userData")?.id,
      },
      headerConfig()
    )
    .then((res) => {
      if (res.data.code === 1) {
        // NotificationManager.success(res.data.message);
        dispatch(getSuccess(res.data.data));
      } else {
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('userData')
      window.localStorage.removeItem('userToken')
      window.localStorage.removeItem('token')
      // NotificationManager.error(err.message);
      dispatch(getFailure(err.message));
    });
};

export const editProfile = (payload) => (dispatch) => {
  axios
    .post(endpoints.editProfile, payload, headerConfig())
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        dispatch(editSuccess(res.data.data));
      } else {
        NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      NotificationManager.error(err.message);
      dispatch(editFailure(err.message));
    });
};
