import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getSuccess = (item) => {
  return {
    type: "CONTACT_SUCCESS",
    payload: item,
  };
};

export const getFailure = (error) => {
  return {
    type: "CONTACT_FAILURE",
    payload: error,
  };
};

export const contactUs = (payload) => (dispatch) => {
  axios
    .post(endpoints.contactUs, payload)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        dispatch(getSuccess(res.data.data));
      } else {
        // NotificationManager.error('Something went wrong!');
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getFailure(err.message));
    });
};
