import React, { useEffect } from "react";
import "./pagenotfound.scss";
// import notFoundPage from "../../assets/404-error.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    // <div className="page-not-found">
    //   <h1 className="title">Page Not Found</h1>
    //   <img src={notFoundPage} alt="not Found Page" />
    // </div>
    <div class="cont_principal cont_error_active">
      <div class="cont_error">
        <h1>404</h1>
        <p>The Page you're looking for isn't here.</p>
        <Link className="button" to="/">
          Back to Home
        </Link>
      </div>
      <div class="cont_aura_1"></div>
      <div class="cont_aura_2"></div>
    </div>
  );
};

export default PageNotFound;
