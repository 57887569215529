import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { cartList } from "../../cartList/actions/cartList.action";
import { endpoints } from "../../endpoint";

export const getAddToCartSuccess = (item) => {
  return {
    type: "ADD_TO_CART_SUCCESS",
    payload: item,
  };
};

export const getAddToCartFailure = (error) => {
  return {
    type: "ADD_TO_CART_FAILURE",
    payload: error,
  };
};

export const addCart = (item, navigate = null) => {
  console.log("ADD LOCAl", item);
  setTimeout(() => {
    if (navigate) {
      navigate("/cart");
    }
  }, 1000);
  return {
    type: "ADD_TO_CART",
    payload: item,
  };
};

export const replaceCart = (data) => {
  return {
    type: "REPLACE_CART",
    payload: data,
  };
};

export const addToCart =
  (payload, getCartList, navigate) =>
  (dispatch) => {
    axios
      .post(endpoints.addToCart, payload)
      .then((res) => {
        if (res.data.code === 1) {
          dispatch(getAddToCartSuccess(res.data));
          // dispatch(addCart(res.data))
          if (getCartList) {
            dispatch(cartList());
          }
          NotificationManager.success(res.data.message);
        } else {
          // NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
        dispatch(getAddToCartFailure(err.message));
      });
  };
