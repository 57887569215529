import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Modal } from "@mui/material";
// import Header from "../Header/Header";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import SplashSampleImage from "../../assets/Splash-City.png";
import SplashMainImage from "../../assets/Splash-Screen.png";
import "./splash.scss";
// import Footer from "../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLocalState } from "../../common/localstorage";
import useWindowWidth from "../../hooks/useWindowWidth";
import { stateList } from "../../redux/stateList/actions/stateList.action";
import { baseURL } from '../../axios'
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { RedCircle } from "../../assets/js/RedCircle";
import { GreenTriangle } from "../../assets/js/GreenTriangle";

const Splash = () => {
  const [open, setOpen] = useState(false)
  const [heading, setHeading] = useState('')
  const [subHeading, setSubHeading] = useState('')
  const [deviceWidth, setDeviceWidth] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.stateListReducer.data);
  const windowWidth = useWindowWidth();

  const getBannerData = async () => {
    const data = await axios.get(`${baseURL}home/banner-content`)
    if (data?.data?.data && data?.data?.data?.status) {
      setHeading(data?.data?.data?.heading)
      setSubHeading(data?.data?.data?.sub_heading)
      setOpen(true)
    }
  }

  useEffect(() => {
    dispatch(stateList());
    getBannerData().then(() => {
      console.log('gotResponse')
    })
    setTimeout(() => {
      window.addEventListener("resize", () =>
        setDeviceWidth(window.innerWidth)
      );
      window.addEventListener("load", () => setDeviceWidth(window.innerWidth));
    }, 1000);
  }, []);

  const handleRedirect = (state) => {
    dispatch({
      type: "SET_SELECTED_STATE",
      payload: state.id,
    });
    setLocalState("selectedStateId", state.id);
    setLocalState("selectedStateName", state.name);
    navigate("/home");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SubscriptionModal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="subscription-model-container"
          style={{
            position: "relative",
            maxHeight: "90vh",
            overflow: "scroll",
          }}
        >
          <div className="subscription-page-cards">
            <IoClose
              className="close-icon"
              style={{
                alignSelf: "flex-start",
                height: 40,
                width: 40,
                paddingRight: "2%",
                paddingTop: "2%",
                position: "absolute",
                top: 0,
                right: 0,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />
            <div className="subscription-page-card subscription-page-left-card">
              <RedCircle
                className="subscription-page-left-card-icon-1"
                fill={"#FBA4A4"}
              />
              <GreenTriangle
                className="subscription-page-left-card-icon-2"
                fill="#E93434"
              />
              <h2 className="subscription-card-amount">{heading}</h2>
              <hr />
              <p
                className="subscription-card-desc"
                dangerouslySetInnerHTML={{
                  __html: subHeading,
                }}
              ></p>
            </div>
          </div>
        </div>
      </SubscriptionModal >
      <div className="splash">
        <div className="splash-main-image">
          <img src={SplashMainImage} href="splash-screen-main-image" />
          <div className="splash-image-details">
            <div className="splash-image-headings">
              <h4>Tiffin Mom’s</h4>
              <h3>Where would you like your order to be delivered?</h3>
            </div>
            <div
              className={`splash-image-cards ${windowWidth < 1489 && "d-none"}`}
            >
              <div className="splash-image-card card-left">
                <div className="splash-image-card-price">$49.99</div>
                <h3>Weekday Tiffin Service</h3>
                <h5>Delivery On Monday and Thursday</h5>
                <p>in between 4:00 PM to 9:00 PM.</p>
              </div>
              <div className="splash-image-card card-right">
                <div className="splash-image-card-price">$79.99</div>
                <h3>Weekly Tiffin Service</h3>
                <h5>Delivery On Monday and Thursday</h5>
                <p>in Between 4:00 PM to 9:00 PM</p>
                <p className="splash-image-card-warning">
                  Last order accepted by 11:59 PM a day before
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="splash-locations">
          <ul className="splash-options">
            {data.length > 0 &&
              data.map((ele, ky) => (
                <li
                  className="splash-option"
                  onClick={() => handleRedirect(ele)}
                  key={ky}
                >
                  <img
                    src={SplashSampleImage}
                    href="splash-city-image"
                    className="splash-city-image"
                    alt="splash-main"
                  />
                  <div className="splash-city-detail">
                    <h5 className="splash-city-title">{ele?.name}</h5>
                    <p className="splash-city-address">{ele?.address}</p>
                  </div>
                  <FmdGoodOutlinedIcon className="splash-location-icon" />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const SubscriptionModal = styled(Modal)`
  .subscription-model-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: min-content;
    background-color: white;
    border: 0;
    outline: 0;
    border-radius: 5px;
    box-shadow: 0 0 20px 0px black;
    padding: 0 3%;

    @media (max-width: 800px) {
      padding: 0 7%;
      .subscription-page-cards {
        flex-direction: column;
      }
    }

    .subscription-page-cards {
      display: flex;
      align-items: center;
      justify-content: space-around;

      hr {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #bdbdbd;
        margin: 10px 0px;
        width: 100%;
      }
      .subscription-page-card {
        position: relative;
        padding: 40px 40px 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 95px 0;
        p {
          text-align: left;
        }
        .subscription-card-title {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 12px;
        }
        .subscription-card-amount {
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 19px;
        }
        .subscription-card-desc {
          font-family: "Merriweather", serif;
          line-height: 1.3;
        }
        .subscription-card-button {
          position: absolute;
          height: 80px;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 24px;
          background-color: #e93434;
          color: #f6f6f6;
          font-weight: 700;
          padding: 2px 34px;
          text-transform: capitalize;
        }
      }
      .subscription-page-left-card {
        flex: 0.45;
        background-color: #f6f6f6;
        color: #606060;
        position: relative;
        .subscription-page-left-card-icon-1 {
          position: absolute;
          bottom: -60px;
          left: -40px;
          z-index: -1;
        }
        .subscription-page-left-card-icon-2 {
          position: absolute;
          top: -70px;
          right: 20%;
          z-index: -1;
        }
      }
      .subscription-page-right-card {
        flex: 0.45;
        background-color: #292929;
        color: white;
        .subscription-page-left-card-icon-1 {
          position: absolute;
          top: -30px;
          right: -30px;
          z-index: -1;
        }
        .subscription-page-left-card-icon-3 {
          position: absolute;
          bottom: -60px;
          right: -75px;
          z-index: -1;
        }
        hr {
          border: 1px solid #bdbdbd;
        }
      }
    }
  }
`;

export default Splash;
