import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getDataSuccess = (item) => {
    return {
        type: "GET_TIFFIN_CATEGORY_SUCCESS",
        payload: item,
    };
};

export const getDataFailure = (error) => {
    return {
        type: "GET_TIFFIN_CATEGORY_FAILURE",
        payload: error,
    };
};

export const tiffinCategoryList = (id) => (dispatch) => {
    axios
        .post(endpoints.tiffinCategory,
            {
                tiffin_id: id,
            })
        .then((res) => {
            if (res.data.code === 1) {
                // NotificationManager.success(res.data.message);
                dispatch(getDataSuccess(res.data.data));
            } else {
                // NotificationManager.error(res.data.message);
            }
        })
        .catch((err) => {
            NotificationManager.error(err.message);
            dispatch(getDataFailure(err.message));
        });
};


