import React, { useEffect, useState } from "react";

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("load", () => setWindowWidth(window.innerWidth));
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setWindowWidth(window.innerWidth)
      );
      window.removeEventListener("load", () =>
        setWindowWidth(window.innerWidth)
      );
    };
  }, []);
  return windowWidth;
};

export default useWindowWidth;
