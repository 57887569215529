import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getDataSuccess = (item) => {
  return {
    type: "HOW_ITS_WORKS_SUCCESS",
    payload: item,
  };
};

export const getDataFailure = (error) => {
  return {
    type: "HOW_ITS_WORKS_FAILURE",
    payload: error,
  };
};

export const howItsWorks = (payload) => (dispatch) => {
  axios
    .post(endpoints.howItsWorks, payload)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        dispatch(getDataSuccess(res.data.data));
      } else {
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getDataFailure(err.message));
    });
};
