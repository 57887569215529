export const COMMON_ACTION_LOADER = 'COMMON_ACTION_LOADER';
export const COMMON_ACTION_SUCCESS = 'COMMON_ACTION_SUCCESS';
export const COMMON_ACTION_ERROR = 'COMMON_ACTION_ERROR';
//modifiers
export const GET_MODIFIER_LOADER = 'GET_MODIFIER_LOADER';
export const GET_MODIFIER_ERROR = 'GET_MODIFIER_ERROR';
export const GET_MODIFIER_SUCCESS = 'GET_MODIFIER_SUCCESS';
export const GET_MODIFIER_SET_SUCCESS = 'GET_MODIFIER_SET_SUCCESS';


export const GET_LIST_LOADER = 'GET_LIST_LOADER';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_ERROR = 'GET_LIST_ERROR';

export const GET_LOGOUT_CART = "GET_LOGOUT_CART";


export const GET_SUB_LOADER = 'GET_SUB_LOADER'
export const GET_SUB_LIST_SUCESS = 'GET_SUB_LIST_SUCESS';
export const GET_SUB_LIST_ERROR = 'GET_SUB_LIST_ERROR';



export const POST_SUB_SUCESS = 'POST_SUB_SUCESS';
export const POST_SUB_ERROR = 'POST_SUB_ERROR';
