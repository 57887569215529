import React, { useEffect } from "react";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import MainPhoto from "../../assets/NonAuthImage.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./signup.scss";
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../common/constants";
import { useDispatch } from "react-redux";
import { signup } from "../../redux/actions/auth";
import { getLocalState } from "../../common/localstorage";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    firstname: Yup.string().required(CONSTANTS.REQUIRED_FIELD),
    lastname: Yup.string().required(CONSTANTS.REQUIRED_FIELD),
    email: Yup.string()
      .email(CONSTANTS.NOT_VALID_EMAIL)
      .required(CONSTANTS.REQUIRED_FIELD),
    password: Yup.string()
      .min(8, CONSTANTS.PASSWORD_MIN_8)
      .required(CONSTANTS.REQUIRED_FIELD),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        email: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
        password: values.password,
      };
      dispatch(signup(data, navigate));
    },
  });

  // useEffect(() => {
  //   if (getLocalState("token")) {
  //     navigate(-1);
  //   }
  // }, []);

  return (
    <div className="signup-page">
      <Avatar
        className="signup-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        sx={{ width: 76, height: 76 }}
        onClick={() => navigate("/splash")}
      />
      <div className="signup-page-container">
        <div className="signup-page-leftbox">
          <img
            src={MainPhoto}
            alt="signup Page"
            className="signup-page-photo"
          />
          <div className="signup-page-left-center">
            <h2 className="signup-page-left-title">Already have an Account?</h2>
            <h5 className="signup-page-left-subtitle">
              Sign In by clicking the button below
            </h5>
            <Button
              className="signup-page-left-button"
              onClick={() => navigate("/signin")}
            >
              Sign In
            </Button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="signup-page-rightbox">
          <h1 className="signup-page-right-title">Sign Up</h1>
          <div className="signup-page-right-firstname-container">
            <h3 className="signup-page-right-firstname-label">First Name</h3>
            <TextField
              className="signup-page-right-firstname-input"
              name="firstname"
              variant="outlined"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
          </div>
          <div className="signup-page-right-lastname-container">
            <h3 className="signup-page-right-lastname-label">Last Name</h3>
            <TextField
              className="signup-page-right-lastname-input"
              name="lastname"
              variant="outlined"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
          </div>
          <div className="signup-page-right-email-container">
            <h3 className="signup-page-right-email-label">Email Address</h3>
            <TextField
              className="signup-page-right-email-input"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="signup-page-right-password-container">
            <h3 className="signup-page-right-password-label">Enter Password</h3>
            <TextField
              type="password"
              className="signup-page-right-password-input"
              name="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          <div className="signup-page-terms-conditions">
            With Singing Up you are accepting our{" "}
            <span>Terms & Conditions</span> and <span>Privacy Policy</span>
          </div>
          <Button type="submit" className="signup-page-right-button">
            Sign Up
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
