export const getLocalState = (key) => {
  const serializedState = localStorage.getItem(key);
  if (!serializedState) {
    return undefined;
  }
  return key === "token" ? serializedState : JSON.parse(serializedState);
};

export const setLocalState = (key, data) => {
  const serializedState = key === "token" ? data : JSON.stringify(data);
  localStorage.setItem(key, serializedState);
};

export const removeLocalState = (key) => {
  localStorage.removeItem(key);
};

export const clearLocalState = () => {
  localStorage.clear();
};
