import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getDataSuccess = (item) => {
  return {
    type: "GET_DATA_SUCCESS",
    payload: item,
  };
};

export const getDataFailure = (error) => {
  return {
    type: "GET_DATA_FAILURE",
    payload: error,
  };
};
export const setSelectedState = (stateId) => {
  return {
    type: "SET_SELECTED_STATE",
    payload: stateId,
  };
};

export const stateList = () => (dispatch) => {
  axios
    .get(endpoints.stateList)
    .then((res) => {
      if (res.data.code === 1) {
        // NotificationManager.success(res.data.message);
        dispatch(getDataSuccess(res.data.data));
      } else {
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getDataFailure(err.message));
    });
};
