const initialValue = {
  data: [],
  error: "",
  selectedState: "",
};

const stateListReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "GET_DATA_SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: "",
      };
    case "GET_DATA_FAILURE":
      return {
        ...state,
        data: [],
        error: action.payload,
      };
    case "SET_SELECTED_STATE":
      return {
        ...state,
        selectedState: action.payload,
      };
    default:
      return state;
  }
};

export default stateListReducer;
