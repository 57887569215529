import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getLocalState,
  removeLocalState,
  setLocalState,
} from "../../common/localstorage";
import AccordianPaymentMethod from "../../components/AccordianPaymentMethod/AccordianPaymentMethod";
import CartBanner from "../../components/CartBanner/CartBanner";
import useWindowWidth from "../../hooks/useWindowWidth";
import { login, signup } from "../../redux/actions/auth";
import {
  cartList,
  getCartTotal,
} from "../../redux/cartList/actions/cartList.action";
import {
  getProfile
} from "../../redux/editProfile/actions/editProfile.actions";
import { deleteUserSelectedSub } from "../../redux/subscription/actions/subscriptions.action";
import { applyCouponeCode } from "../../redux/verifyCoupon/verifyCoupon";
import BillingDetail from "./BillingDetail";
import "./checkout.scss";
import ShippingDetail from "./ShippingDetail";

// Imports braintree related
import DropIn from "braintree-web-drop-in-react";
import { getClientToken, makePayment } from "../../apiCalls";

const cartTotal = [
  {
    name: "subtotal",
    amount: 0,
    shipping: "",
  },
  {
    name: "Shipping",
    amount: "",
    shipping: "-",
  },
  {
    name: "Internet Convenience Fee",
    amount: 0,
    shipping: "",
  },
  {
    name: "Tax",
    amount: 0,
    shipping: "",
  },
  {
    name: "Total",
    amount: 0,
    shipping: "",
  },
];

const productDetails = [
  {
    product_id: 1,
    price: 10,
    quantity: 1,
  },
  {
    product_id: 2,
    price: 20,
    quantity: 1,
  },
  {
    product_id: 3,
    price: 15,
    quantity: 1,
  },
];

const Checkout = () => {
  const promoCodeData = useSelector((state) => state?.promocodeReducer?.data);

  const [loading, setLoading] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const cartData = useSelector((state) => state?.cartListReducer?.data);
  const subTotalFromCart = useSelector(
    (state) => state?.cartListReducer?.subTotal
  );
  const totalFromCart = useSelector((state) => state?.cartListReducer?.total);
  const user = localStorage.getItem("userData");
  const token = localStorage.getItem("userToken");
  const userToken = useSelector((state) => state?.userReducer?.userToken);
  const subscriptionData = useSelector((state) => state?.SubscriptionReducer);
  const stateData = useSelector((state) => state.stateListReducer);
  const [promocode, setPromoCode] = useState(promoCodeData?.promocode ?? "");
  const [subscription, setSubscription] = useState(0);
  const subscriptions = useSelector(
    (state) => state?.cartListReducer?.subscription
  );
  const [subtotalPrice, setSubtotalPrice] = useState(
    Number(subscriptionData?.sub_values?.price)
  );
  const [totalPrice, setTotalPrice] = useState(
    Number(subscriptionData?.sub_values?.price) +
    (Number(subscriptionData?.sub_values?.price) * 4) / 100 +
    (Number(subscriptionData?.sub_values?.price) * 7) / 100
  );
  const [userLoginForm, setUserLoginForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    rememberme: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const windowWidth = useWindowWidth();
  useEffect(() => {
    dispatch(getProfile());
  }, []);

  const [billingDetails, setBillingDetails] = useState({
    address1: "",
    address2: "",
    city: "",
    companyName: "",
    country: "USA",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    postCode: "",
    state: "",
    notes: "",
  });
  const [diffAddress, setDiffAddress] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    companyName: "",
    country: "USA",
    firstName: "",
    lastName: "",
    notes: "",
    postCode: "",
    state: "",
  });
  const [validationMessage, setValidationMessage] = useState("");

  const [braintreeValues, setBraintreeValues] = useState({
    clientToken: null,
    success: "",
    error: "",
    instance: "",
  });

  useEffect(() => {
    checkAllFields();
  }, [billingDetails, shippingAddress, diffAddress]);

  useEffect(() => {
    // setSubtotalPrice(Number(subscriptionData?.sub_values?.price));
    setSubtotalPrice(0);
    setTotalPrice(
      Number(subscriptionData?.sub_values?.price) +
      (Number(subscriptionData?.sub_values?.price) * 4) / 100 +
      (Number(subscriptionData?.sub_values?.price) * 7) / 100
    );
  }, [subscriptionData]);

  useEffect(() => {
    const updatedSubTotal =
      Number(subscriptionData?.sub_values?.price) -
      (Number(subscriptionData?.sub_values?.price) *
        Number(promoCodeData?.value)) /
      100;
    setSubtotalPrice(updatedSubTotal);
    setTotalPrice(
      updatedSubTotal +
      (updatedSubTotal * 4) / 100 +
      (updatedSubTotal * 7) / 100
    );
  }, [promoCodeData]);

  useEffect(() => {
    if (cartData) {
      let total = 0;
      // let temp = JSON.parse(JSON.stringify(cartData));
      const hasSubscription = subscriptions?.filter(
        (j) => j?.use_quantity < j?.quantity
      );
      cartData?.map((i, index) => {
        const includes = hasSubscription?.filter(
          (j) => j?.tiffin_id == i?.tiffin_id
        );
        if (i?.type === "Tiffin" && includes?.length) {
          total = total + (i?.price - includes[0]?.price);
          // console.log("IIIIII", i?.price,includes[0]?.price)
          // temp[index].price = i?.price - includes[0]?.price;
        } else {
          const qty = i?.quantity !== 0 ? i?.quantity : 1;
          total = total + i?.price * qty;
        }

      });

      if (promoCodeData?.value) {
        total = total - (total * promoCodeData?.value) / 100;
      }
      // total = total.toFixed(2)
      const taxApplied = stateData?.selectedState == '3' ? 10 : 6.625;
      const tax = (total * taxApplied) / 100;
      const internetFee = (total * 4) / 100;
      cartTotal[2].amount = internetFee.toFixed(2);
      cartTotal[3].amount = tax.toFixed(2);
      const subTotalCart = parseFloat(Number(total).toFixed(2)) + parseFloat(Number(internetFee).toFixed(2)) + parseFloat(Number(tax).toFixed(2))
      // alert(JSON.stringify({total: total.toFixed(2), internetFee: internetFee.toFixed(2), tax: tax.toFixed(2)}))
      console.log({ total: parseFloat(Number(total).toFixed(2)) + parseFloat(Number(internetFee).toFixed(2)) + parseFloat(Number(tax).toFixed(2)) }, '--------------------------------')
      setTotal(subTotalCart);
      setSubTotal(total);
      dispatch(getCartTotal(total));
    }
  }, [promoCodeData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
    setBillingDetails({
      ...billingDetails,
      firstName: getLocalState("userData")?.first_name ?? "",
      lastName: getLocalState("userData")?.last_name ?? "",
      email: getLocalState("userData")?.email ?? "",
      // state:
      //   stateData?.data?.length > 0
      //     ? stateData?.data[0]?.name
      //     : getLocalState("selectedStateId"),
    });
    setShippingAddress({
      ...shippingAddress,
      firstName: getLocalState("userData")?.first_name ?? "",
      lastName: getLocalState("userData")?.last_name ?? "",
    });
  }, []);

  useEffect(() => {
    if (token) {
      if (!location?.state?.fromSubscription) {
        dispatch(cartList());
        setLoading(true);
        setSubTotal(subTotalFromCart);
        setTotal(totalFromCart);
        if (subTotalFromCart === 0 || totalFromCart === 0) {
          subscriptions?.length === 0 && NotificationManager.error("Your Cart is Empty and Total Price is 0.");
          // navigate("/tiffinordering");
        }
        const isSubscription = getLocalState("is_subscription");
        const stl = getLocalState("checkout_sub_total");
        const ttl = getLocalState("checkout_total");
        if (isSubscription) {
          const sbl = parseFloat(subTotalFromCart) + parseFloat(stl);
          const tl = parseFloat(totalFromCart) + parseFloat(ttl);
          // setSubscription(isSubscription);
          // setSubTotal(sbl ?? 0);
          // setTotal(tl ?? 0);
        }
      } else {
        setLoading(true);
        setSubTotal(0);
        setTotal(0);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => {
      dispatch(deleteUserSelectedSub());
      removeLocalState("is_subscription");
      removeLocalState("checkout_sub_total");
      removeLocalState("checkout_total");
    };
  }, [subTotalFromCart]);

  useEffect(() => {
    if (subTotalFromCart) {
      setSubTotal(subTotalFromCart);
      setTotal(totalFromCart);
      if (subTotalFromCart === 0 || totalFromCart === 0) {
        subscriptions?.length === 0 && NotificationManager.error("Your Cart is Empty and Total Price is 0.");
        navigate("/tiffinordering");
      }
      const isSubscription = localStorage.getItem("is_subscription");
      const stl = localStorage.getItem("checkout_sub_total");
      const ttl = localStorage.getItem("checkout_total");
      if (isSubscription) {
        const sbl = parseFloat(subTotalFromCart) + parseFloat(stl);
        const tl = parseFloat(totalFromCart) + parseFloat(ttl);
        // setSubscription(isSubscription);
        // setSubTotal(sbl ?? 0);
        // setTotal(tl ?? 0);
        // console.log("TOTLA IN",sbl, tl, isSubscription);
      }
    }
  }, [totalFromCart, subTotalFromCart]);

  useEffect(() => {
    checkAllFields();
  }, [validationMessage]);

  useEffect(() => {
    setLocalState("totalAmount", total);
  }, [total]);

  const getToken = () => {
    getClientToken().then((response) => {
      console.log("response", response);
      if (response.err) {
        setBraintreeValues({ ...braintreeValues, error: response.error });
      } else {
        setBraintreeValues({
          ...braintreeValues,
          clientToken: response.data.clientToken,
        });
      }
    });
  };

  const onPurchase = () => {
    braintreeValues.instance.requestPaymentMethod().then((data) => {
      if (data) {
        let nonce = data.nonce;
        let payment_data = {
          payment_method_nonce: nonce,
          amount: 100,
        };
        console.log("payment_data", payment_data);
        makePayment(payment_data)
          .then((response) => {
            console.log("response ::::: 252", response);
            if (response.err) {
              setBraintreeValues({ ...braintreeValues, error: response.err });
            } else {
              setBraintreeValues({
                ...braintreeValues,
                error: "",
                success: response.success,
              });
            }
          })
          .catch((err) =>
            setBraintreeValues({ ...braintreeValues, error: err, success: "" })
          );
      }
    });
  };

  const handleFormChange = (key, value) => {
    setUserLoginForm({
      ...userLoginForm,
      [key]: value,
    });
  };

  const handleSignin = () => {
    if (userLoginForm.email && userLoginForm.password)
      dispatch(
        login(
          { email: userLoginForm.email, password: userLoginForm.password },
          navigate,
          true
        )
      );
    setTimeout(() => {
      checkAllFields();
    }, 1000);
  };

  const handleSignUp = () => {
    if (
      !!userLoginForm.first_name &&
      !!userLoginForm.last_name &&
      !!userLoginForm.email &&
      !!userLoginForm.password
    )
      dispatch(signup(userLoginForm, navigate, true));
    setTimeout(() => {
      checkAllFields();
    }, 1000);
  };

  if (location.state && location?.state?.fromSubscription) {
    Object.keys(subscriptionData?.sub_values)?.length === 0 && navigate(-1);
  }

  const checkAllFields = () => {
    if (diffAddress) {
      if (!billingDetails.firstName) {
        setValidationMessage("First Name is required in Billing Details");
        return true;
      } else if (!billingDetails.lastName) {
        setValidationMessage("Last Name is required in Billing Details");
        return true;
      } else if (!billingDetails.address1) {
        setValidationMessage(
          "Street Address Line 1 is required in Billing Details"
        );
        return true;
      } else if (!billingDetails.city) {
        setValidationMessage("City is required in Billing Details");
        return true;
      } else if (!billingDetails.state) {
        setValidationMessage("State is required in Billing Details");
        return true;
      } else if (!billingDetails.postCode) {
        setValidationMessage("Postal Code is required in Billing Details");
        return true;
      } else if (!billingDetails.phone) {
        setValidationMessage("Phone Number is required in Billing Details");
        return true;
      } else if (!billingDetails.email) {
        setValidationMessage("Email is required in Billing Details");
        return true;
      } else if (!shippingAddress.firstName) {
        setValidationMessage("First Name is required in Shipping Details");
        return true;
      } else if (!shippingAddress.lastName) {
        setValidationMessage("Last Name is required in Shipping Details");
        return true;
      } else if (!shippingAddress.address1) {
        setValidationMessage(
          "Street Address Line 1 is required in Shipping Details"
        );
        return true;
      } else if (!shippingAddress.city) {
        setValidationMessage("City is required in Shipping Details");
        return true;
      } else if (!shippingAddress.state) {
        setValidationMessage("State is required in Shipping Details");
        return true;
      } else if (!shippingAddress.postCode) {
        setValidationMessage("Postal Code is required in Shipping Details");
        return true;
      } else {
        setValidationMessage("");
        return false;
      }
    } else {
      const tempToken = localStorage.getItem("userToken");
      if (
        tempToken == null ||
        tempToken == "null" ||
        tempToken == undefined ||
        tempToken == "undefined"
      ) {
        setValidationMessage("Please login or signup to place order.");
        return true;
      } else if (!billingDetails.firstName) {
        setValidationMessage("First Name is required in Billing Details");
        return true;
      } else if (!billingDetails.lastName) {
        setValidationMessage("Last Name is required in Billing Details");
        return true;
      } else if (!billingDetails.address1) {
        setValidationMessage(
          "Street Address Line 1 is required in Billing Details"
        );
        return true;
      } else if (!billingDetails.city) {
        setValidationMessage("City is required in Billing Details");
        return true;
      } else if (!billingDetails.state) {
        setValidationMessage("State is required in Billing Details");
        return true;
      } else if (!billingDetails.postCode) {
        setValidationMessage("Postal Code is required in Billing Details");
        return true;
      } else if (!billingDetails.phone) {
        setValidationMessage("Phone Number is required in Billing Details");
        return true;
      } else if (!billingDetails.email) {
        setValidationMessage("Email is required in Billing Details");
        return true;
      } else {
        setValidationMessage("");
        return false;
      }
    }
  };

  const applyPromocode = (e) => {
    console.log("promocode", promocode);
    const data = {
      state_id: stateData?.selectedState,
      promocode: promocode,
    };
    dispatch(applyCouponeCode(data));
  };

  console.log(typeof token);
  return (
    <div className="checkout-wrapper">
      <div
        className="loading-backdrop"
        style={{
          display: loading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      <CartBanner />
      <div className="checkout-detail-wrapper">
        {(token == null || token == undefined || token == "null") && (
          <div className="checkout-login">
            <div className="login-label">
              Returning customer? Click here to login
            </div>
            <Box component="form" noValidate autoComplete="off">
              <div className="form-field">
                <label>Email Address</label>
                <TextField
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </div>
              <div className="form-field">
                <label>Password</label>
                <TextField
                  type={"password"}
                  onChange={(e) => handleFormChange("password", e.target.value)}
                />
              </div>
              <div className="form-field">
                <FormControlLabel
                  onChange={(e) =>
                    handleFormChange("rememberme", e.target.checked)
                  }
                  control={<Checkbox defaultChecked />}
                  label="Remember Me"
                />
              </div>
              <div className="form-field">
                <Button className="" onClick={() => handleSignin()}>
                  Sign In
                </Button>
              </div>
            </Box>
          </div>
        )}
        {(token == null || token == undefined || token == "null") && (
          <div className="checkout-login">
            <div className="login-label">
              Forgot your password ? Click here to reset
            </div>
            <Box component="form" noValidate autoComplete="off">
              <div className="form-field">
                <Button
                  className=""
                  onClick={() => navigate("/forgotPassword")}
                >
                  Forgot password
                </Button>
              </div>
            </Box>
          </div>
        )}
        {(token == null || token == undefined || token == "null") && (
          <div className="checkout-login">
            <div className="login-label">
              New customer? Click here to Signup
            </div>
            <Box component="form" noValidate autoComplete="off">
              <div className="form-field">
                <label>First Name</label>
                <TextField
                  onChange={(e) =>
                    handleFormChange("first_name", e.target.value)
                  }
                />
              </div>
              <div className="form-field">
                <label>Last Name</label>
                <TextField
                  onChange={(e) =>
                    handleFormChange("last_name", e.target.value)
                  }
                />
              </div>
              <div className="form-field">
                <label>Email</label>
                <TextField
                  onChange={(e) => handleFormChange("email", e.target.value)}
                />
              </div>
              <div className="form-field">
                <label>Password</label>
                <TextField
                  type="password"
                  onChange={(e) => handleFormChange("password", e.target.value)}
                />
              </div>
              <div className="form-field form-signup">
                <Button className="" onClick={() => handleSignUp()}>
                  Sign Up
                </Button>
              </div>
            </Box>
          </div>
        )}
        {/* coupon form */}
        {/* {!token && ( */}
        <div className="coupon-wrapper">
          <div className="login-label">Have a Coupon Code?</div>
          <div className="coupon-label">
            <label>Apply Coupon Code:</label>
            <input
              type="text"
              value={promocode}
              onChange={(e) => setPromoCode(e.target.value)}
            />
            <button
              onClick={() => {
                if (!user) {
                  NotificationManager.warning("Please Login to Continue");
                } else {
                  applyPromocode();
                }
              }}
            >
              Apply Coupon
            </button>
          </div>
        </div>
        {/* )} */}

        {/* billing */}
        <div className="billing-wrapper">
          <div className="billing-detail">
            <div className="billing-add">
              <h2>Billing Details</h2>
              <BillingDetail
                billingDetails={billingDetails}
                setBillingDetails={setBillingDetails}
                isSubscription={location?.state?.fromSubscription}
              />
            </div>
            <div className="shipping-add">
              <h2 style={{ marginBottom: 0 }}>Shipping Address</h2>
              <FormControlLabel
                style={{ display: "flex" }}
                onChange={(e) => {
                  setDiffAddress(e.target.checked);
                }}
                control={<Checkbox />}
                label="Ship to a different address?"
              />
              {diffAddress && (
                <ShippingDetail
                  shippingAddress={shippingAddress}
                  setShippingAddress={setShippingAddress}
                  isSubscription={location?.state?.fromSubscription}
                  diffAddress={diffAddress}
                />
              )}
            </div>
          </div>
          <div className="order-detail">
            <h2>Your Order</h2>
            <div className="cart-total-table">
              {cartTotal.map((item, index) => (
                <div className="tr" key={index}>
                  <div className="th">{item.name}</div>
                  {location.state && location?.state?.fromSubscription ? (
                    item.name === "subtotal" || item.name === "Total" ? (
                      <div className="td">
                        {item?.name === "subtotal"
                          ? "$" + parseFloat(subscriptionData?.sub_values?.price).toFixed(2)
                          : "$" +
                          parseFloat(subscriptionData?.sub_values?.price +
                            (subscriptionData?.sub_values?.price * 4) / 100 +
                            (subscriptionData?.sub_values?.price * 7) / 100).toFixed(2)}
                      </div>
                    ) : (
                      <div className="td">
                        {item.shipping
                          ? item.shipping
                          : "$" +
                          (item?.name === "Tax"
                            ? (subscriptionData?.sub_values?.price * 7) / 100
                            : (subscriptionData?.sub_values?.price * 4) /
                            100)}
                      </div>
                    )
                  ) : item.name === "subtotal" || item.name === "Total" ? (
                    <div className="td">
                      {console.log({ a: item?.name })}
                      {item?.name === "subtotal"
                        ? "$" + subTotal.toFixed(2)
                        : "$" + total.toFixed(2)}
                    </div>
                  ) : subscription === true ? (
                    <div className="td">{"$0"}</div>
                  ) : (
                    <div className="td">
                      {item.shipping ? item.shipping : "$" + item.amount}
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* {validate() ?  */}
            <div className="payment-method">
              {/* {!!location?.state?.fromSubscription ? (
                <AccordianPaymentMethod
                  productDetails={
                    cartData && cartData?.length > 0 ? cartData : productDetails
                  }
                  total={total}
                  subTotal={subTotal}
                  billingDetails={billingDetails}
                  shippingAddress={shippingAddress}
                  userToken={userToken || token}
                  isSubscription={location?.state?.fromSubscription}
                  subscriptionData={{
                    subscription_id: subscriptionData?.sub_values?.id,
                    price:
                      subscriptionData?.sub_values?.price +
                      (subscriptionData?.sub_values?.price * 4) / 100 +
                      (subscriptionData?.sub_values?.price * 7) / 100,
                  }}
                />
              ) : ( */}
              <AccordianPaymentMethod
                productDetails={
                  cartData && cartData?.length > 0 ? cartData : productDetails
                }
                disableButton={validationMessage !== ""}
                total={total}
                subTotal={subTotal}
                billingDetails={billingDetails}
                shippingAddress={shippingAddress}
                userToken={userToken || token}
                isSubscription={location?.state?.fromSubscription}
                subscriptionData={{
                  subscription_id: subscriptionData?.sub_values?.id,
                  price:
                    subscriptionData?.sub_values?.price +
                    (subscriptionData?.sub_values?.price * 4) / 100 +
                    (subscriptionData?.sub_values?.price * 7) / 100,
                }}
              />
              {/* )} */}
            </div>
            <div className="payment">
              {braintreeValues?.clientToken ? (
                <div>
                  <DropIn
                    options={{ authorization: braintreeValues?.clientToken }}
                    onInstance={(instance) =>
                      setBraintreeValues({
                        ...braintreeValues,
                        instance: instance,
                      })
                    }
                  />
                  <Button onClick={() => onPurchase()}>BUY</Button>
                </div>
              ) : (
                <h1>Loading...</h1>
              )}
            </div>
            <div
              style={{
                margin: "10px",
                color: "red",
              }}
            >
              {validationMessage}
            </div>
            {/* <div style={{width: '100%', marginTop: 100}}>
                <Button className="place-order-button" style={{backgroundColor: '#e93434', color: '#FFFFFF', width: '80%'}} onClick={() => validate()}>
                  Place Order
                </Button>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
