import { useLocation } from "react-router-dom";
import React from "react";
import Footer from "../pages/Footer/Footer";
import Header from "../pages/Header/Header";

export function PrivateRoute(props) {
  const location = useLocation();

  if (location.pathname === "/splash") {
    console.log("data splash");
  } else {
    console.log("no route defin");
  }

  return (
    <>
      {location.pathname === "/splash" ? (
        <>
          {props.children}
          <Footer />
        </>
      ) : (
        <>
          <Header />
          {props.children}
          <Footer />
        </>
      )}
    </>
  );
}

export function PublicRoute({ children }) {
  return <>{children}</>;
}
