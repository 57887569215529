import "./App.scss";
// packages or third party libraries
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getProfile,
} from "../src/redux/editProfile/actions/editProfile.actions";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Navigate, Route, Routes } from "react-router-dom";

// Local Imports
import "swiper/css/bundle";
import { PrivateRoute, PublicRoute } from "./common/authGuard";
import LocationModal from "./components/LocationModal/LocationModal";
import { REACT_APP_PAYPAL_CLIENT_ID } from "./configs/EnvConfig";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import SubscriptionCheckout from "./pages/Checkout/SubscriptionCheckout";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import ContactUs from "./pages/ContactUs/ContactUs";
import Faq from "./pages/Faq/Faq";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import MyOrder from "./pages/MyOrder/MyOrder";
import MyProfile from "./pages/MyProfile/MyProfile";
import MySubscription from "./pages/MySubscription/MySubscription";
import OtpVerification from "./pages/OtpVerification/OtpVerification";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import ProductSinglePage from "./pages/ProductSinglePage/ProductSinglePage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Signin from "./pages/Signin/Signin";
import Signup from "./pages/Signup/Signup";
import Splash from "./pages/Splash/Splash";
import Subscription from "./pages/Subscription/Subscription";
import TiffinOrdering from "./pages/TiffinOrdering/TiffinOrdering";
import TrayMenu from "./pages/TrayMenu/TrayMenu";
import WorkProcess from "./pages/WorkProcess/WorkProcess";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
  }, []);
  return (
    <PayPalScriptProvider
      options={{
        "client-id": REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        "enable-funding": 'venmo'
      }}
    >
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Navigate to="/splash" />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/splash"
            element={
              <PrivateRoute>
                <Splash />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <Signin />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/location"
            element={
              <PublicRoute>
                <LocationModal />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/forgotpassword"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/resetpassword"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/otpverification"
            element={
              <PublicRoute>
                <OtpVerification />
              </PublicRoute>
            }
          ></Route>
          <Route
            path="/subscription"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/gallery"
            element={
              <PrivateRoute>
                <Gallery />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/traymenu"
            element={
              <PrivateRoute>
                <TrayMenu />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/productdetail"
            element={
              <PrivateRoute>
                <ProductDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/contactus"
            element={
              <PrivateRoute>
                <ContactUs />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/myprofile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/tiffinordering"
            element={
              <PrivateRoute>
                <TiffinOrdering />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/productsinglepage"
            element={
              <PrivateRoute>
                <ProductSinglePage />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/cart"
            element={
              <PrivateRoute>
                <Cart />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/checkout"
            element={
              <PrivateRoute>
                <Checkout />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/subscription-checkout"
            element={
              <PrivateRoute>
                <SubscriptionCheckout />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/myorder"
            element={
              <PrivateRoute>
                <MyOrder />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/mysubscription"
            element={
              <PrivateRoute>
                <MySubscription />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/faq"
            element={
              <PrivateRoute>
                <Faq />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/work-process"
            element={
              <PrivateRoute>
                <WorkProcess />
              </PrivateRoute>
            }
          ></Route>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/comming" element={<ComingSoon />} />
        </Routes>
      </div>
    </PayPalScriptProvider>
  );
}

export default App;
