import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./homePageImageGallery.scss";

const HomePageImageGallery = ({ gallerydata }) => {
  return (
    <div className="homepage-image-gallery">
      <h1 className="homepage-image-gallery-title">Gallery</h1>
      <Swiper
        modules={[Pagination]}
        className="homepage-image-gallery-images"
        breakpoints={{
          1096: {
            slidesPerView: 4,
          },
          812: {
            slidesPerView: 3,
          },
          530: {
            slidesPerView: 2.5,
          },
          424: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1.5,
          },
        }}
      >
        {gallerydata.length > 0 &&
          gallerydata.map((ele) => (
            <SwiperSlide>
              <div className="homepage-image-gallery-image-container">
                <img src={ele.image} className="homepage-image-gallery-image" />
              </div>
            </SwiperSlide>
          ))}
        {/* <SwiperSlide>
          <div className="homepage-image-gallery-image-container">
            <img src={GalleryPhoto2} className="homepage-image-gallery-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="homepage-image-gallery-image-container">
            <img src={GalleryPhoto3} className="homepage-image-gallery-image" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="homepage-image-gallery-image-container">
            <img src={GalleryPhoto4} className="homepage-image-gallery-image" />
          </div>
        </SwiperSlide> */}
      </Swiper>
      {/* <Button className="homepage-image-gallery-btn">View More</Button> */}
    </div>
  );
};

export default HomePageImageGallery;
