import styled from "@emotion/styled";
import { Button, Modal } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GreenTriangle } from "../../assets/js/GreenTriangle";
import { LeftArrow } from "../../assets/js/LeftArrow";
import { RedCircle } from "../../assets/js/RedCircle";
import { RightArrow } from "../../assets/js/RightArrow";
import { getLocalState, setLocalState } from "../../common/localstorage";
import HomePageCities from "../../components/HomePageCities/HomePageCities";
import HomePageDishes from "../../components/HomePageDishes/HomePageDishes";
import HomePageImageGallery from "../../components/HomePageImageGallery/HomePageImageGallery";
import HomePageInfo from "../../components/HomePageInfo/HomePageInfo";
import { homeData } from "../../redux/homeData/actions/homedata.actions.js";
import {
  getSubList,
  userSelectedSub,
} from "../../redux/subscription/actions/subscriptions.action";
import "./home.scss";
import WhatsappIcon from '../../assets/whatsapp-logo.svg';

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState();

  const mainImageSwiperRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sliderdata = useSelector((state) => state.homeDataReducer.slider);
  const homeDataLoading = useSelector((state) => state.homeDataReducer.isDone);
  const stateData = useSelector((state) => state.stateListReducer);
  const gallerydata = useSelector((state) => state.homeDataReducer.gallery);
  const { sub_list, sub_loader, isDone } = useSelector(
    (state) => state.SubscriptionReducer
  );

  const whatsAppLink = 'https://wa.me/17329601819';

  useEffect(() => {
    setLoading(true);
    setOpen(true);

    fetchDetails();

    if (!getLocalState("token")) {
      setOpen(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const fetchDetails = async () => {
    let data = {
      state_id: stateData.selectedState
        ? stateData.selectedState
        : getLocalState("selectedStateId"),
    };
    dispatch(homeData(data));
    dispatch(
      getSubList({
        state_id: data.state_id,
      })
    );
  };

  return (
    <>
      <div className="homepage">
        <div
          className="loading-backdrop"
          style={{
            display: homeDataLoading || isDone ? "none" : "flex",
          }}
        >
          <CircularProgress color="error" />
        </div>
        <div className="homepage-main-image-container">
          <div
            className="swiper-slide-btn swiper-slide-prev-btn"
            onClick={() => mainImageSwiperRef.current.slidePrev()}
          >
            <LeftArrow />
          </div>
          <div
            className="swiper-slide-btn swiper-slide-next-btn"
            onClick={() => mainImageSwiperRef.current.slideNext()}
          >
            <RightArrow />
          </div>
          {/* <div className="homepage-main-image-details">
            <h2 className="homepage-main-image-heading-1">Tifin Mom's</h2>
            <h2 className="homepage-main-image-heading-2">
              Asia Cucumber Salad
            </h2>
            <h6 className="homepage-main-image-heading-3">
              Asian Cucumber Salad Recipe made with crunchy cucumber, onion,
              rice wine vinegar, and a few secret ingredients!
            </h6>
            <Button
              variant="contained"
              className="homepage-main-image-order-button"
              onClick={() => navigate("/traymenu")}
            >
              Order Now
            </Button>
          </div> */}
          <Swiper
            onSwiper={(swiper) => (mainImageSwiperRef.current = swiper)}
            className="homepage-main-image-swiper"
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop={true}
            modules={[Autoplay, Pagination]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
          >
            {sliderdata.length > 0 &&
              sliderdata?.map((ele) => (
                <SwiperSlide onClick={() => navigate("/tiffinOrdering")}>
                  <img
                    src={ele.image}
                    alt="Swiper 1"
                    className="swiper-image"
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <HomePageInfo />
        <HomePageDishes />
        {gallerydata?.length > 0 && (
          <HomePageImageGallery gallerydata={gallerydata} />
        )}
        <HomePageCities />
        {sub_list?.length > 0 && (
          <SubscriptionModal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {!(sub_loader && loading) ? (
              <>
                <div
                  className="subscription-model-container"
                  style={{
                    position: "relative",
                    maxHeight: "90vh",
                    overflow: "scroll",
                  }}
                >
                  <div className="subscription-page-cards">
                    <IoClose
                      className="close-icon"
                      style={{
                        alignSelf: "flex-start",
                        height: 40,
                        width: 40,
                        paddingRight: "2%",
                        paddingTop: "2%",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => setOpen(false)}
                    />
                    {sub_list?.map((item) => (
                      <div className="subscription-page-card subscription-page-left-card">
                        <RedCircle
                          className="subscription-page-left-card-icon-1"
                          fill={"#FBA4A4"}
                        />
                        <GreenTriangle
                          className="subscription-page-left-card-icon-2"
                          fill="#E93434"
                        />
                        <h3 className="subscription-card-title">{item.name}</h3>
                        <h2 className="subscription-card-amount">
                          {"$" + item.price}
                        </h2>
                        <hr />
                        <p
                          className="subscription-card-desc"
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        ></p>
                        <Button
                          className="subscription-card-button"
                          onClick={() => {
                            dispatch(userSelectedSub(item));
                            setLocalState("is_subscription", true);
                            setLocalState("checkout_sub_total", item?.price);
                            setLocalState("checkout_total", item?.price);
                            navigate("/subscription-checkout", {
                              state: { fromSubscription: true },
                            });
                          }}
                        >
                          Pay Now
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </SubscriptionModal>
        )}
        <div style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          right: '20px', // Adjust as needed
          zIndex: '9999', // Ensure it's above other content
          cursor: 'pointer'
        }}>
          <img src={WhatsappIcon} onClick={() => window.open(whatsAppLink, '_blank')} alt="WhatsApp" style={{ width: '100px', height: '100px' }} />
        </div>
      </div>
    </>
  );
};

const SubscriptionModal = styled(Modal)`
  .subscription-model-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: min-content;
    background-color: white;
    border: 0;
    outline: 0;
    border-radius: 5px;
    box-shadow: 0 0 20px 0px black;
    padding: 0 3%;

    @media (max-width: 800px) {
      padding: 0 7%;
      .subscription-page-cards {
        flex-direction: column;
      }
    }

    .subscription-page-cards {
      display: flex;
      align-items: center;
      justify-content: space-around;

      hr {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: #bdbdbd;
        margin: 10px 0px;
        width: 100%;
      }
      .subscription-page-card {
        position: relative;
        padding: 40px 40px 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 95px 0;
        p {
          text-align: left;
        }
        .subscription-card-title {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 12px;
        }
        .subscription-card-amount {
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 19px;
        }
        .subscription-card-desc {
          font-family: "Merriweather", serif;
          line-height: 1.3;
        }
        .subscription-card-button {
          position: absolute;
          height: 80px;
          bottom: -40px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 24px;
          background-color: #e93434;
          color: #f6f6f6;
          font-weight: 700;
          padding: 2px 34px;
          text-transform: capitalize;
        }
      }
      .subscription-page-left-card {
        flex: 0.45;
        background-color: #f6f6f6;
        color: #606060;
        position: relative;
        .subscription-page-left-card-icon-1 {
          position: absolute;
          bottom: -60px;
          left: -40px;
          z-index: -1;
        }
        .subscription-page-left-card-icon-2 {
          position: absolute;
          top: -70px;
          right: 20%;
          z-index: -1;
        }
      }
      .subscription-page-right-card {
        flex: 0.45;
        background-color: #292929;
        color: white;
        .subscription-page-left-card-icon-1 {
          position: absolute;
          top: -30px;
          right: -30px;
          z-index: -1;
        }
        .subscription-page-left-card-icon-3 {
          position: absolute;
          bottom: -60px;
          right: -75px;
          z-index: -1;
        }
        hr {
          border: 1px solid #bdbdbd;
        }
      }
    }
  }
`;

export default Home;
