const initialValue = {
    data: [],
    error: "",
    selectedTiffin: "",
  };
  
  const tiffinListReducer = (state = initialValue, action) => {
    switch (action.type) {
      case "GET_TIFFIN_DATA_SUCCESS":
        return {
          ...state,
          data: action.payload,
          error: "",
        };
      case "GET_TIFFIN_DATA_FAILURE":
        return {
          ...state,
          data: [],
          error: action.payload,
        };
      case "SET_SELECTED_TIFFIN":
        return {
          ...state,
          selectedTiffin: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default tiffinListReducer;
  