import styled from "@emotion/styled";
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';
// import Modal from '@material-ui/core/Modal';
// import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  Badge,
  Button,
  Fade,
  // makeStyles,
  Modal,
  Popover,
  Typography,
  Dialog,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { HiMenu, HiOutlineLocationMarker } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { TbChevronDown } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import { getLocalState } from "../../common/localstorage";
import LocationModal from "../../components/LocationModal/LocationModal";
import useWindowWidth from "../../hooks/useWindowWidth";
import { logout } from "../../redux/actions/auth";
import { replaceCart } from "../../redux/addToCart/actions/addToCart.action";
import "./header.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6, 10, 6),
  },
  "& .MuiTextField-root": {
    margin: theme.spacing(1),
    width: "300px",
  },
  "& .MuiButtonBase-root": {
    margin: theme.spacing(2),
  },
}));

const Header = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShowModal] = useState(false);
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const token = localStorage.getItem("token");
  const windowWidth = useWindowWidth();
  // const number = useSelector((state) => state?.cartListReducer?.data?.length)
  const addCart = useSelector((state) => state?.cartListReducer?.data?.length);

  useEffect(() => {
    // console.log("ROURE", route);
    // if (token) {

    // }
    dispatch({
      type: "SET_SELECTED_STATE",
      payload: getLocalState("selectedStateId"),
    });
  }, []);

  const logoutuser = () => {
    dispatch(logout());
    dispatch(replaceCart([]));
    navigate("/splash");
    localStorage.clear();
    setShowModal(false);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="main-header">
      <div className="main-header-container">
        <Avatar
          alt="Tiffin Mom Logo"
          src={TiffinMomLogo}
          sx={{ width: 76, height: 76 }}
          onClick={() => navigate("/home")}
        />
        <div className="main-header-menu">
          <Link to={"/home"}>
            <h6>Home</h6>
          </Link>
          <Link to={"/traymenu"}>
            <h6>Tray Menu</h6>
          </Link>
          <Link to={"/tiffinordering"}>
            <h6>Tiffin Ordering</h6>
          </Link>
          <Link to={"/subscription"}>
            <h6>Subscription</h6>
          </Link>
          <Link
            onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfO4--Vm1FTOsTdxYTuYiGqxrQZAWB7YjD_-YNplDVH1FEgMQ/viewform?usp=sharing', '_blank', 'noreferrer')}
            >
            <h6>Franchise Oppurtunity</h6>
          </Link>
          <Link to={"/gallery"}>
            <h6>Gallery</h6>
          </Link>
          <Link to={"/contactus"}>
            <h6>Contact Us</h6>
          </Link>
        </div>

        <div className="main-header-menu-icons">
          {sidebarVisible ? (
            <IoClose
              className="close-icon"
              onClick={() => setSidebarVisible(!sidebarVisible)}
            />
          ) : (
            <>
              <HiOutlineLocationMarker
                className={windowWidth <= 790 && "d-none"}
                onClick={handleOpen}
              // maxwidth="sm"
              // fullwidth="true"
              />
              <Dialog
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={show}
                onClose={handleClose}
              >
                <div
                  style={{
                    padding: "20px 40px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                  }}
                >
                  <h2
                    style={{
                      width: "auto",
                    }}
                  >
                    By switching location you will lost all data including login
                  </h2>
                  <p>Are you sure want to logout and remove data?</p>
                  <div>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={logoutuser}
                    >
                      Yes
                    </Button>
                    <Button type="button" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Dialog>
              <Badge badgeContent={addCart} color="primary">
                <AiOutlineShopping onClick={() => navigate("/cart")} />
              </Badge>

              <BiUser
                className={windowWidth <= 790 && "d-none"}
                onClick={handlePopoverClick}
              ></BiUser>

              <StyledPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{ vertical: -10, horizontal: "right" }}
              >
                {token ? (
                  <>
                    <ul>
                      <li onClick={() => navigate("/myprofile")}>My Profile</li>
                      <hr />
                      <li onClick={() => navigate("/myorder")}>My Orders</li>
                      <hr />
                      <li onClick={() => navigate("/mysubscription")}>
                        My Subscription
                      </li>
                      <hr />
                      <li onClick={logoutuser}>Logout</li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul>
                      <li onClick={() => navigate("/signin")}>Sign In</li>
                      <hr />
                      <li onClick={() => navigate("/signup")}>Sign Up</li>
                    </ul>
                  </>
                )}
              </StyledPopover>
              <HiMenu
                onClick={() => setSidebarVisible(!sidebarVisible)}
                className="d-not-none"
              />
            </>
          )}
        </div>
      </div>
      <div
        className="main-header-drawer"
        style={{ marginLeft: sidebarVisible ? "0" : "100%" }}
      >
        <div className="main-header-drawer-container">
          <div className="main-header-drawer-menus">
            <Link
              to={"/home"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Home</h6>
            </Link>
            <Link
              to={"/traymenu"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Tray Menu</h6>
            </Link>
            <Link
              to={"/tiffinordering"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Tiffin Ordering</h6>
            </Link>
            <Link
              to={"/subscription"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Subscription</h6>
            </Link>
            <Link
              onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSfO4--Vm1FTOsTdxYTuYiGqxrQZAWB7YjD_-YNplDVH1FEgMQ/viewform?usp=sharing', '_blank', 'noreferrer')}
            >
              <h6>Franchise Oppurtunity</h6>
            </Link>
            <Link
              to={"/gallery"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Gallery</h6>
            </Link>
            <Link
              to={"/contactus"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <h6>Contact Us</h6>
            </Link>
            <Link to={""} onClick={() => setSidebarVisible(!sidebarVisible)}>
              <h6 onClick={handleOpen}>Location</h6>
            </Link>
            <Link
              to={"/cart"}
              onClick={() => setSidebarVisible(!sidebarVisible)}
            >
              <Badge badgeContent={addCart} color="primary">
                <h6>Cart</h6>
              </Badge>
            </Link>
          </div>
          {true && token ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<TbChevronDown className="expand-icon" />}
              >
                <Typography>My Account</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <h6
                  onClick={() => {
                    navigate("/myprofile");
                    setSidebarVisible(!sidebarVisible);
                  }}
                >
                  My Profile
                </h6>
                <h6
                  onClick={() => {
                    navigate("/myorder");
                    setSidebarVisible(!sidebarVisible);
                  }}
                >
                  My Orders
                </h6>
                <h6
                  onClick={() => {
                    navigate("/mysubscription");
                    setSidebarVisible(!sidebarVisible);
                  }}
                >
                  My Subscriptions
                </h6>
                <h6 onClick={logoutuser}>Logout</h6>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Button
              onClick={() => {
                navigate("/signin");
              }}
              className="login-btn"
            >
              Login
            </Button>
          )}
          <LocationModal show={show} onHide={() => setShowModal(!show)} />
        </div>
      </div>
    </div>
  );
};

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    width: max-content;
    padding: 20px;
    border-radius: 25px;
    background-color: #f7f7f7;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        padding: 0;
        color: #606060;
        font-weight: 700;
        cursor: pointer;
      }
      hr {
        border: 1px solid #f7962a;
      }
    }
  }
`;

export default Header;
