import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getOrderDataSuccess = (item) => {
    return {
      type: "GET_ORDER_DATA_SUCCESS",
      payload: item,
    };
  };
  
  export const getOrderDataFailure = (error) => {
    return {
      type: "GET_ORDER_DATA_FAILURE",
      payload: error,
    };
  };

  export const orderListData = () => (dispatch) => {
    axios
      .get(endpoints.orderList)
      .then((res) => {
        if (res.data.code === 1) {
            console.log("response data::::",res)
          // NotificationManager.success(res.data.message);
          dispatch(getOrderDataSuccess(res.data.data));
        } else {
          // NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        // NotificationManager.error(err.message);
        dispatch(getOrderDataFailure(err.message));
      });
  };

  
