
export const setUserData = (data) => {
 return {
  type: "SET_USER_DATA",
  payload: data,
 };
};

export const setUserToken = (token) => {
 return {
  type: "SET_USER_TOKEN",
  payload: token,
 };
};

export const setLoading = (load) => {
 return {
  type: "SET_LOADING",
  payload: load,
 };
};


