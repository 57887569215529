import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { headerConfig } from "../../../common/headerConfig";
import { endpoints } from "../../endpoint";

export const getDataSuccess = (item) => {
    return {
      type: "GET_TIFFIN_DATA_SUCCESS",
      payload: item,
    };
  };
  
  export const getDataFailure = (error) => {
    return {
      type: "GET_TIFFIN_DATA_FAILURE",
      payload: error,
    };
  };
  export const setSelectedTiffin = (id) => {
    return {
      type: "SET_SELECTED_TIFFIN",
      payload: id,
    };
  };

  export const tiffinList = (state_id) => (dispatch) => {
    axios
      .get(endpoints.tiffinList + "/" + state_id, headerConfig())
      .then((res) => {
        if (res.data.code === 1) {
          // NotificationManager.success(res.data.message);
          dispatch(getDataSuccess(res.data.data));
        } else {
          // NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
        dispatch(getDataFailure(err.message));
      });
  };

  
