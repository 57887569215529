export const LeftArrow = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <filter id="shadow" color-interpolation-filters="sRGB">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="1"
        flood-opacity="1"
        flood-color="#e93434"
      />
    </filter>
    <path
      d="M1 7L7 13M1 7L7 1M1 7L13 7"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
