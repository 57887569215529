import {
    COMMON_ACTION_SUCCESS,
    COMMON_ACTION_ERROR,
    COMMON_ACTION_LOADER,
    GET_LIST_LOADER,
    GET_LIST_SUCCESS,
    GET_LIST_ERROR,
} from '../Type'

const initialState = {
    //post
    loader: false,
    success: null,
    error: null,
    //get
    list_data: null,
    get_loader: true,
    get_success: null,
    get_error: null,
}

const CommonReducer = function (state = initialState, action) {
    switch (action.type) {
        case COMMON_ACTION_LOADER: {
            return {
                ...state,
                loader: true,
            }
        }
        case COMMON_ACTION_SUCCESS: {
            return {
                ...state,
                loader: false,
                error: null,
                success: action.payload,
            }
        }
        case COMMON_ACTION_ERROR: {
            return {
                ...state,
                loader: false,
                error: action.payload,
                success: [],
            }
        }
        //get
        case GET_LIST_LOADER:
            return {
                ...state,
                get_loader: true,
                get_error: null,
            }
        case GET_LIST_SUCCESS:
            return {
                ...state,
                get_loader: false,
                list_data: action.payload,
                error: null,
            }
        case GET_LIST_ERROR:
            return {
                ...state,
                get_loader: false,
                list_data: null,
                error: action.payload,
            }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default CommonReducer
