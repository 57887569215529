const initialValue = {
    data: [],
    error: "",
  };
  
  const orderListReducer = (state = initialValue, action) => {
    switch (action.type) {
      case "GET_ORDER_DATA_SUCCESS":
        return {
          ...state,
          data: action.payload,
          error: "",
        };
      case "GET_ORDER_DATA_FAILURE":
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default orderListReducer;
  