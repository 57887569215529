import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CONSTANTS } from "../../common/constants";
import { getLocalState } from "../../common/localstorage";
import {
  editProfile,
  getProfile,
} from "../../redux/editProfile/actions/editProfile.actions";
import "./myProfile.scss";

const MyProfile = () => {
  const dispatch = useDispatch();
  const { getData } = useSelector((state) => state.editUserReducer);
  const navigate = useNavigate();

  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const validationSchema = Yup.object({
    firstname: Yup.string().required(CONSTANTS.REQUIRED_FIELD),
    lastname: Yup.string().required(CONSTANTS.REQUIRED_FIELD),
    email: Yup.string()
      .email(CONSTANTS.NOT_VALID_EMAIL)
      .required(CONSTANTS.REQUIRED_FIELD),
  });

  const formik = useFormik({
    initialValues: {
      firstname: getData?.first_name,
      lastname: getData?.last_name,
      email: getData?.email,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        email: values.email,
        first_name: values.firstname,
        last_name: values.lastname,
      };
      dispatch(editProfile(data));
    },
  });

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  return (
    <div className="my-profile">
      <div className="my-profile-container">
        {/* <div className="profile-logo">
          <h1>My Profile</h1>
          <Avatar {...stringAvatar("Vraj Patel")} />
        </div> */}
        <form onSubmit={formik.handleSubmit} className="my-profile-form">
          <div className="my-profile-form-firstname-container">
            <h3 className="my-profile-form-firstname-label">First Name</h3>
            <TextField
              className="my-profile-form-firstname-input"
              name="firstname"
              variant="outlined"
              value={formik.values.firstname}
              onChange={formik.handleChange}
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
            />
          </div>
          <div className="my-profile-form-lastname-container">
            <h3 className="my-profile-form-lastname-label">Last Name</h3>
            <TextField
              className="my-profile-form-lastname-input"
              name="lastname"
              variant="outlined"
              value={formik.values.lastname}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
            />
          </div>
          <div className="my-profile-form-email-container">
            <h3 className="my-profile-form-email-label">Email Address</h3>
            <TextField
              className="my-profile-form-email-input"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <Stack
            direction={"row"}
            spacing={2}
            className="my-profile-form-btn-container"
          >
            <Button className="my-profile-form-button change-pass-btn">
              Change Password
            </Button>
            <Button type="submit" className="my-profile-form-button save-btn">
              Save
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default MyProfile;
