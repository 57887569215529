import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getLocalState } from "../../common/localstorage";
import Card from "./Card/Card";
import "./homepageCities.scss";

const citiesData = [
  {
    "id": 4,
    "data": [
      {
        "title": "Central and Northern Jersey",
        "data": [
          "Princeton",
          "Lawrenceville",
          "Robbinsville",
          "East/West Windsor",
          "Hightstown",
          "Cranbury",
          "Plainsboro",
          "South/North/East/New Brunswick",
          "Dayton",
          "Monroe",
          "Edison",
          "Somerset",
          "Hillsborough",
          "Piscataway",
          "Woodbridge",
          "Elizabeth",
          "Newark",
          "Jersey City",
          "Hoboken",
          "Newport",
          "Secaucus",
          "Parsippany",
          "Bridgewater"
        ]
      },
      {
        "title": "South Jersey",
        "data": [
          "Ewing, NJ",
          "Trenton, NJ",
          "Mt. Laurel, NJ",
          "Moorestown, NJ",
          "Southampton, NJ",
          "Mt. Holly, NJ",
          "Willingboro, NJ",
          "Burlington, NJ",
          "Florence Twsp, NJ",
          "Hamilton, NJ"
        ]
      },
      {
        "title": "Pennsylvania",
        "data": [
          "Langhorne, PA",
          "Bristol, PA",
          "Andalusia, PA",
          "Rockledge, PA",
          "Kings Of Prussia, PA",
          "Exton, PA"
        ]
      }
    ]
  },
  {
    "id": 3,
    "data": [
      {
        "title": "Warrenville",
        "data": [
          "Chicago, IL",
          "Aurora, IL",
          "Naperville, IL",
          "Joliet, IL",
          "Waukegan, IL",
          "Cicero, IL",
          "Hammond, IL",
          "Arlington Heights, IL",
          "Schaumburg, IL",
          "Bolingbrook, IL",
          "Palatine, IL",
          "Skokie, IL",
          "Tinley Park, IL"
        ]
      }
    ]
  },
  {
    "id": 6,
    "data": [
      {
        "title": "New York",
        "data": [
          "Brooklyn",
          "Staten Island",
          "Manhattan",
          "Queens"
        ]
      }
    ]
  }
];

const HomePageCities = () => {
  const stateData = useSelector((state) => state.stateListReducer);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    let data = {
      state_id: stateData?.selectedState
        ? stateData?.selectedState
        : getLocalState("selectedStateId"),
    };
    const temp = citiesData?.filter((i) => i?.id == data?.state_id);
    if (temp?.length > 0) {
      setCardData(temp[0]?.data);
    }
  }, []);

  return (
    <div className="homepage-cities">
      <div style={{ fontSize: 30, color: '#FFFFFF', marginBottom: 20 }}>{stateData?.selectedState == 7 ? 'Delivery in 50 miles in radius of location: 3320 E market street, York, PA-17402' : 'Delivery in Few Miles in Radius of Below Areas'}</div>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        breakpoints={{
          1060: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 2.5,
          },
          590: {
            slidesPerView: 2,
          },
          450: {
            slidesPerView: 1.5,
          },
          0: {
            slidesPerView: 1,
          },
        }}
      >
        {cardData?.map((item, i) => (
          <SwiperSlide>
            <Card item={item} index={i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomePageCities;
