import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalState } from "../../common/localstorage";
import CartBanner from "../../components/CartBanner/CartBanner";
import { faq } from "../../redux/faq/actions/faq.action";
import "./Faq.scss";

// const faqQuestions = [
//   {
//     id: 1,
//     question: "Do you serve outside NJ?",
//     ans: "At present we deliver in NJ as per the cities mentioned in our homepage on our website tiffinmom.com. But we will be starting to deliver it shortly in your Area. We would appreciate if you like us on Facebook to remain in the loop for us",
//   },
//   {
//     id: 2,
//     question: "When do u deliver your food order?",
//     ans: "We deliver every order on Monday / Wednesday / Friday in between 4 and 9 pm. Feel free to contact us back if you have any further queries.s",
//   },
//   {
//     id: 3,
//     question: "Can you deliver only Roti / chapatti?",
//     ans: "We currently do not do Roti/Chapati only orders. It works as a part of weekly tiffin.",
//   },
//   {
//     id: 4,
//     question: "Can u take only thepla orders?",
//     ans: "We currently do not do Thepla only orders. It works as an add on to your weekly tiffin.",
//   },
//   {
//     id: 5,
//     question: "Can I have 1 more Veggie if I don’t Order any of Roti / Rice ?",
//     ans: "Unfortunately we no longer do that. Our concept is to provide complete meal plan on every alternate day which includes Rice / Roti.",
//   },
//   {
//     id: 6,
//     question: "What if I order only Trays and chapattis and want deliver other then Delivery Schedule Days?",
//     ans: "Delivery charge is only waived if the order is shipped with other tiffins on Monday / Wednesday / Friday, meaning you can order tray instead of tiffin. If you would like it delivered on any other day except for days mentioned, there will be $29.99 delivery charge.",
//   },
//    {
//     id: 7,
//     question: "Allergies to Nuts and Seasame?",
//     ans: "Our food is prepared in a facility which also processes milk, eggs, fish, crustacean shellfish, peanuts, tree nuts, wheat and soy. Even though we do not prepare any non veg / meat, but we source our supplies from vendors that process all the other things.",
//   },
//   {
//     id: 8,
//     question: "Review us on Facebook ?",
//     ans: "You are welcome Sir / Madam. Please consider writing us a Facebook review. As a startup it really helps us out. You can reach our Facebook page by clicking onto the Facebook icon at the top of our website and click on reviews tab once in Facebook.",
//   },
//   {
//     id: 9,
//     question: "What if food spoilt ?",
//     ans: "We are extremely sorry and we do apologize for the spoilt curry. This things usually never happens with us. But we will be taking actions for the same as to why this has happened. And will let you know too for the same. But for now we offer 1 free curry (4th curry) for your next Order. Once again we are sorry and we do apologize for the inconvenience. Also appreciate your feedback. This will surely help us to improve better. Feel free to contact us back if you have any further queries.",
//   },
//   {
//     id: 10,
//     question: "How to save Curries from being spoilt ?",
//     ans: "Here are few things we have made a note from our experience and would like to suggest you the same. While using Food only take out ample amount of food which you will be consuming at that time and heat it accordingly. Do not heat  the entire container if you are not consuming the entire container at the same time. Usually the food once heated and again kept in the fridge has more chances of getting spoilt. We are not sure about how do you consume it but this is just a suggestion from our side.",
//   },
// ];

const Faq = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.stateListReducer);
  const faqData = useSelector((state) => state.faqReducer.data);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    let data = {
      state_id: stateData.selectedState
        ? stateData.selectedState
        : getLocalState("selectedStateId"),
      page: 0,
    };

    dispatch(faq(data));
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="faq-wrapper">
      <div
        className="loading-backdrop"
        style={{
          display: loading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      <CartBanner />
      <div className="faq-content-wrapper">
        <h2 className="section-title">FAQs</h2>
        <div className="faq-content">
          {faqData.length > 0 &&
            faqData?.map((item) => (
              <div key={item.id} className="questions">
                <h3 className="question">
                  Q.<span>{item.question}</span>
                </h3>
                <p className="ans">
                  Ans.<span>{item.answer}</span>
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
