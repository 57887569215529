import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import React from "react";
import "./AccordianPaymentMethod.scss";

import PaymentGateway from "../PaymentGateway/PaymentGateway";

const AccordianPaymentMethod = ({
  productDetails,
  total,
  billingDetails,
  shippingAddress,
  userToken,
  isSubscription,
  subscriptionData,
  disableButton,
  subTotal,
}) => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [totalPriceOfCart, setTotalPriceOfCart] = React.useState(1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="accordian-payment">
      {/* <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>
            <FormControlLabel
              value="female"
              control={<Radio />}
            //   label={cardLabel()}
            />
            <div className="card-detail">
              <span className="label">Credit card (Square)</span>
              <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
              </ul>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="accordian-detail-data">
            <span>Pay with your credit card via Square</span>
            <form>
              <div className="form-field">
                <label>
                  Card Number<span>*</span>
                </label>
                <input className="card-number" type="text" placeholder=".... .... .... ...." />
              </div>
              <div className="form-field w-50">
                <label>
                  Expiry (MM/YY)<span>*</span>
                </label>
                <input type="text" placeholder="MM/YY" />
              </div>
              <div className="form-field w-50">
                <label>
                  Card Code<span>*</span>
                </label>
                <input type="text" placeholder="CVV" />
              </div>
              <div className="form-field">
                <label>
                  Card Postal Code<span>*</span>
                </label>
                <input type="text" placeholder="Card Postal Code" />
              </div>
            </form>
          </div>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>
            <FormControlLabel
              value="female"
              control={<Radio />}
            //   label={cardLabel()}
            />
            <div className="card-detail"><span className="label">Google Pay (Square)</span></div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat
            lectus, varius pulvinar diam eros in elit. Pellentesque convallis
            laoreet laoreet.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* Accordion for Paypal payment gateway */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>
            <FormControlLabel
              value="female"
              control={<Radio checked />}
            //   label={cardLabel()}
            />
            <div className="card-detail">
              <span className="label">Paypal</span>
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PaymentGateway
            productDetails={productDetails}
            totalAmount={total}
            subTotalAmount={subTotal}
            billingDetails={billingDetails}
            shippingAddress={shippingAddress}
            userToken={userToken}
            isSubscription={isSubscription}
            subscriptionData={subscriptionData}
            disableButton={disableButton}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordianPaymentMethod;
