import { NotificationManager } from "react-notifications";
import { headerConfig } from "../../common/headerConfig";
import { setUserData, setUserToken } from "../user/action";
import axios from "./../../axios";
import { endpoints } from "./../endpoint";

export const signup =
  (data, navigate, isFromCart = false) =>
  (dispatch) => {
    axios
      .post(endpoints.sign_up, data)
      .then((res) => {
        if (isFromCart) {
          const responseData = JSON.parse(JSON.stringify(res));
          const user = responseData?.data?.data;
          console.log("User SINgup", responseData);
          if (user) {
            axios.defaults.headers["Authorization"] = "Bearer " + user?.token;
            axios.defaults.headers["token"] = user?.token;
            //
            dispatch(setUserData(user));
            dispatch(setUserToken(user.token));
            localStorage.setItem("userData", JSON.stringify(user));
            localStorage.setItem("token", user.token);
            localStorage.setItem("userToken", user.token);
            localStorage.setItem("is_subscription", false);
            NotificationManager.success(responseData?.data?.message);
          } else {
            NotificationManager.error(responseData?.data?.message);
          }
        } else {
          if (res.data.code === 1) {
            NotificationManager.success(res.data.message);
            navigate("/signin");
          } else {
            NotificationManager.error(res.data.message);
          }
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
      });
  };
export const login =
  (data, navigate, isFromCheckout = false) =>
  (dispatch) => {
    console.log("C");
    localStorage.setItem("userData", null);
    localStorage.setItem("token", null);
    localStorage.setItem("userToken", null);
    localStorage.setItem("is_subscription", false);
    axios
      .post(endpoints.log_in, data)
      .then((res) => {
        const data = JSON.parse(JSON.stringify(res));
        const user = data?.data?.data;
        if (user) {
          axios.defaults.headers["Authorization"] = "Bearer " + user?.token;
          axios.defaults.headers["token"] = user?.token;
          //
          dispatch(setUserData(user));
          dispatch(setUserToken(user.token));
          localStorage.setItem("userData", JSON.stringify(user));
          localStorage.setItem("token", user.token);
          localStorage.setItem("userToken", user.token);
          localStorage.setItem("is_subscription", false);
          NotificationManager.success(data?.data?.message);

          if (!localStorage.getItem('cartRoute')) !isFromCheckout && navigate("/splash");
          else {
            const cartRoute = localStorage.getItem('cartRoute')
            dispatch({
              type: "SET_SELECTED_STATE",
              payload: localStorage.getItem('selectedStateId'),
            });
            localStorage.removeItem('cartRoute')
            navigate(cartRoute)
          };
        } else {
          NotificationManager.error(data.data.message);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.message);
      });
  };

const doLogout = () => {
  return { type: "LOGOUT_REQUEST_SUCCESS" };
};

export const logout = () => (dispatch) => {
  axios
    .post(endpoints.logout, headerConfig())
    .then((res) => {
      // if (res.data.code === 1) {
      // NotificationManager.success(res.data.message);
      dispatch(setUserData(null));
      dispatch(setUserToken(null));
      // axios.defaults.headers.common.token = ``;
      localStorage.setItem("userData", null);
      localStorage.setItem("token", null);
      localStorage.setItem("userToken", null);
      localStorage.clear();
      NotificationManager.success("Logout Successfully!");
      // } else {
      // NotificationManager.error(res.data.message);
      // }
    })
    .catch((err) => {
      // axios.defaults.headers.common.token = ``;
      localStorage.setItem("userData", null);
      localStorage.setItem("token", null);
      localStorage.clear();
      NotificationManager.success("Logout Successfully!");
    });
};

export const forgotEmail = (data, navigate) => (dispatch) => {
  axios
    .post(endpoints.sendEmail, data)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        navigate("/otpverification", { state: { email: data.email } });
      } else {
        NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    });
};

export const verifyOtp = (data, navigate) => (dispatch) => {
  axios
    .post(endpoints.verifyOtp, data)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        navigate("/resetPassword", { state: { email: data.email } });
      } else {
        NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    });
};

export const resetPassword = (data, navigate) => (dispatch) => {
  axios
    .post(endpoints.resetPassword, data)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        navigate("/signin");
      } else {
        NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      NotificationManager.error(err.message);
    });
};
