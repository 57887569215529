import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import { getLocalState, removeLocalState } from "../../common/localstorage";
import {
  getCartDataSuccess,
  getCartTotal,
} from "../../redux/cartList/actions/cartList.action";
import { endpoints } from "../../redux/endpoint";
import { deleteUserSelectedSub } from "../../redux/subscription/actions/subscriptions.action";
import { CircularProgress } from "@mui/material";

const PaymentGateway = ({
  currency = "USD",
  description = "Tiffin",
  productDetails,
  totalAmount,
  subTotalAmount,
  billingDetails,
  shippingAddress,
  userToken,
  isSubscription,
  subscriptionData,
  disableButton,
}) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  // Create a state to set order once transaction is initiated
  const [orderId, setOrderId] = useState(false);
  // Create a state to set success flag once transaction is completed at buyer's end
  const [success, setSuccess] = useState(false);
  // Create a state to set buyer details once transaction is completed at buyer's end
  const [buyerDetails, setBuyerDetails] = useState({});
  // const userToken = useSelector((state) => state?.userReducer?.userToken);
  const navigate = useNavigate();
  const stateData = useSelector((state) => state.stateListReducer);
  const promoCodeData = useSelector((state) => state?.promocodeReducer?.data);

  useEffect(() => {
    console.log("FIF", stateData, userToken, localStorage.getItem("userToken"));
    const tkn = userToken ? userToken : localStorage.getItem("userToken");
    setToken(tkn);
  }, []);

  useEffect(() => {
    if (userToken) {
      console.log("TOKE", userToken);
      setToken(userToken);
    }
  }, [userToken]);

  const saveOrderDetails = async (data = {}) => {
    setLoading(true)
    if (isSubscription) {
      try {
        const res = await axios.post(endpoints.usersub, {
          ...subscriptionData,
          transaction_id: orderId,
        });
        if (res?.data?.code === 1) {
          dispatch(deleteUserSelectedSub());
          removeLocalState("is_subscription");
          removeLocalState("checkout_sub_total");
          removeLocalState("checkout_total");
          dispatch(getCartDataSuccess([]));
          dispatch(getCartTotal(0));
          NotificationManager.success(res?.data?.message);
          navigate("/mysubscription");
        } else {
          NotificationManager.error(res?.data?.message);
        }
      } catch (err) {
        NotificationManager.error(err.message);
      }
      removeLocalState("totalAmount");
    } else {
      try {
        const res = await axios.post(endpoints.placeOrderDetails, data);
        if (res?.data?.code === 1) {
          dispatch(getCartDataSuccess([]));
          dispatch(getCartTotal(0));
          localStorage.setItem("is_subscription", false);
          localStorage.setItem("checkout_sub_total", 0);
          localStorage.setItem("checkout_total", 0);
          NotificationManager.success(res?.data?.message);
          navigate("/home");
        } else {
          NotificationManager.error(res?.data?.message);
        }
      } catch (err) {
        NotificationManager.error(err.message);
      }
      removeLocalState("totalAmount");
    }
  };

  // Creates a paypal order with the amount, description and currency
  const createOrder = (data, actions) => {
    console.log("CREATE ORDER", totalAmount, localStorage.getItem("userToken"));
    const nonSubAmount = getLocalState("totalAmount");
    let mainAmount = 1;
    if (isSubscription) {
      mainAmount = subscriptionData?.price;
    } else {
      if (nonSubAmount) {
        mainAmount = nonSubAmount;
      } else {
        mainAmount = 1;
      }
    }
    if (localStorage.getItem("userToken")) {
      return actions.order
        .create({
          purchase_units: [
            {
              description,
              amount: {
                currency_code: currency,
                value: mainAmount.toFixed(2),
              },
            },
          ],
          // not needed if a shipping address is actually needed
          application_context: {
            shipping_preference: "NO_SHIPPING",
          },
        })
        .then((orderId) => {
          // We will set order id once transaction is initiated
          console.log(orderId);
          // setOrderId(orderId);
          return orderId;
        });
    } else {
      NotificationManager.error("Please login or signup to Place order!");
    }
  };

  // Creates a function to check for approved events
  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log(details);
      setOrderId(details?.id);
      // We will set the buyer details once transaction is completed
      //  get orderid
      // We will set success to true once transaction is completed
      setSuccess(true);
    });
  };

  // Create a error callback where we can handle any errors if occur
  const onError = (data, actions) => {
    console.log("ERROR", data, actions);
    // alert("An Error occured with your payment ");
  };

  useEffect(() => {
    // Here we will do further navigation once we got success from transaction completed
    if (success && orderId) {
      const transactionData = {
        orderId,
      };
      if (buyerDetails && Object.keys(buyerDetails).length) {
        const { payer, ...otherDetails } = buyerDetails;
        if (payer) {
          transactionData["buyerDetails"] = payer;
        }
        transactionData["otherDetails"] = { ...otherDetails };
      }
      const code = stateData.selectedState == 1 ? "_PB" : "_KN";
      const shipping = { ...shippingAddress, notes: billingDetails?.notes };
      const { notes, ...rest } = billingDetails;
      console.log("rest", rest, billingDetails);
      const payload = {
        state_id: stateData.selectedState || 1,
        billing_details: rest,
        shipping_details:
          Object?.keys(shipping).length > 0 &&
            shipping.firstName &&
            shipping?.address1
            ? shipping
            : billingDetails,
        sub_total: subTotalAmount,
        shipping_charge: 0,
        final_total: totalAmount,
        products: productDetails,
        transaction_id: orderId + code,
        order_id: orderId + code,
        promocode_id: promoCodeData?.id,
        discount: promoCodeData?.value,
      };

      if (!promoCodeData?.id) {
        delete payload.promocode_id;
        delete payload.discount;
      }
      // Here we are calling the api to save transaction data
      saveOrderDetails(payload);
    }
  }, [success, orderId]);

  return (
    <div className="accordian-payment">
      <div
        className="loading-backdrop"
        style={{
          display: loading ? "flex" : "none"
        }}
      >
        <CircularProgress color="warning" />
      </div>
      <React.Fragment>
        {/* Here we are rendering paypal buttons from its package */}
        <PayPalButtons
          disabled={disableButton}
          style={{ layout: "vertical" }}
          createOrder={createOrder}
          onApprove={onApprove}
          onError={onError}
        />
      </React.Fragment>
    </div>
  );
};

export default PaymentGateway;
