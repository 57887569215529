import { Chip } from "@mui/material";
import React from "react";
import "./card.scss";

const cardHeader = [
  "Entire New Jersey & Parts of Philadelphia"
];

const Card = ({item, index }) => {
  return (
    <div className="card-cities">
      <div className="card-title-cities">
        <h1>{item.title}</h1>
      </div>
      <div className="card-container">
        {item?.data?.map((i) => {
          return(<Chip label={i} variant="outlined" />);
        })}
        {/* <Chip label="Watson" variant="outlined" />
        <Chip label="Blackiston Mill" variant="outlined" />
        <Chip label="Clarksville" variant="outlined" />
        <Chip label="New Albany" variant="outlined" />
        <Chip label="Jeffersonville" variant="outlined" />
        <Chip label="Shively" variant="outlined" />
        <Chip label="Greenwood" variant="outlined" />
        <Chip label="Prairie Village" variant="outlined" />
        <Chip label="Heritage Creek" variant="outlined" />
        <Chip label="Ashville" variant="outlined" />
        <Chip label="Hopewell" variant="outlined" />
        <Chip label="Lynnview" variant="outlined" />
        <Chip label="West Buechel" variant="outlined" />
        <Chip label="Worthington" variant="outlined" />
        <Chip label="Prospect" variant="outlined" />
        <Chip label="Hurstbourne" variant="outlined" />
        <Chip label="Middletown" variant="outlined" />
        <Chip label="Lyndon" variant="outlined" />
        <Chip label="Northfield" variant="outlined" />
        <Chip label="Parkwood" variant="outlined" />
        <Chip label="Knopp" variant="outlined" /> */}
      </div>
    </div>
  );
};

export default Card;
