const initialValue = {
  productData: {},
  error: "",
};

const productReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "GET_PRODUCT_SUCCESS":
      return {
        ...state,
        productData: action.payload.data,
        error: "",
      };
    case "GET_PRODUCT_FAILURE":
      return {
        ...state,
        productData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
