import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropdownArrow } from "../../assets/js/DropdownArrow";
import CircularProgress from "@mui/material/CircularProgress";
import {
  addCart,
  addToCart,
} from "../../redux/addToCart/actions/addToCart.action";
import { NotificationManager } from "react-notifications";
import "./productCard.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #e93434",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const ProductCard = ({ image, name, price, id, medPrice, largePrice }) => {
  const [inWishList, setInWishList] = useState();
  const [apiLoading, setApiLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [type, setType] = useState(1)
  const [open, setOpen] = useState(false);
  const stateData = useSelector((state) => state.stateListReducer);
  const [cartData, setCartData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleAddToCart = () => {
    if (!token) {
      NotificationManager.error("Please login to continue");
      localStorage.setItem('cartRoute', window.location.pathname)
      navigate('/signin')
    } else if (quantity === 0) {
      NotificationManager.error("Please add 1 quantity");
    } else {
      setApiLoading(true)
      let data = {
        state_id: 1,
        product_id: id,
        quantity: quantity,
        price: getPrice(type),
        type: "Single",
        name: name,
        description: "",
        product_type: type
      };
      setCartData([...cartData, data]);
      if (token) {
        dispatch(addToCart(data));
        setApiLoading(false)
      } else {
        dispatch(addCart(data));
      }
    }
  };

  const handleQuantity = (e) => {
    console.log('type', type)
    if (type === 1) {
      console.log('type', type, e)
      NotificationManager.error("Please select 1 option");
    }
    else setQuantity(e);
  };

  const toggleModal = () => setOpen(!open);
  const setProductType = (e) => setType(e)
  const getPrice = (t) => {
    if (t === 2) return price
    else if (t === 3) return medPrice
    else if (t === 4) return largePrice
    else return ''
  }

  return (
    <div className="product-card">
      <div
        className="loading-backdrop"
        style={{
          display: apiLoading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      <div className="product-card-image-container">
        <img src={image} className="product-card-image"></img>
      </div>
      <div className="product-card-details">
        <h5 className="product-card-title">{name}</h5>
        <div className="product-card-price">{type === 1 ? '' : `$${getPrice(type)}`}</div>
        <br></br>
        <Select
          className="tray-filter-select"
          IconComponent={DropdownArrow}
          defaultValue={1}
          onChange={(e) => setProductType(e.target.value)}
        >
          <MenuItem value={1}>Select</MenuItem>
          {price && <MenuItem value={2}>Small 8-10 ppl</MenuItem>}
          {medPrice && <MenuItem value={3}>Medium 20-22 ppl</MenuItem>}
          {largePrice && <MenuItem value={4}>Large upto 50 ppl</MenuItem>}
        </Select>
        <div className="product-card-numbers">
          <div
            className="product-minus product-btn"
            onClick={() => {
              quantity > 0 && handleQuantity(quantity - 1);
            }}
          >
            {" "}
            -{" "}
          </div>
          <div className="product-number">{quantity}</div>
          <div
            className="product-plus product-btn"
            onClick={() => {
              handleQuantity(quantity + 1);
            }}
          >
            {" "}
            +{" "}
          </div>
        </div>
        {/* <Select
          className="product-card-select"
          defaultValue={0}
          IconComponent={(props) => <DropdownArrow {...props} fill="#e93434" />}
          displayEmpty
          onChange={(e) => handleQuantity(e)}
        >
          <MenuItem disabled hidden value="">
            <em>Select an Option</em>
          </MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select> */}
        <div className="product-card-btns">
          {/* <Button
            className="product-card-wishlist"
            onClick={() => setInWishList(!inWishList)}
          >
            {inWishList ? (
              <AiFillHeart color="#ffffff" fontSize={"25px"} />
            ) : (
              <AiOutlineHeart color="#ffffff" fontSize={"25px"} />
            )}
          </Button> */}
          <Button
            onClick={handleAddToCart}
            disabled={quantity === 0}
            className="product-card-add-to-cart"
          >
            Add To Cart
          </Button>
          <Modal
            open={open}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                Item Successfully added to Cart.
              </Typography>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
