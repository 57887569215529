import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SplashMainImage from "../../assets/Splash-Screen.png";
import { getLocalState } from "../../common/localstorage";
import useWindowWidth from "../../hooks/useWindowWidth";
import {
  setSelectedTiffin,
  tiffinList,
} from "../../redux/tiffin/action/tiffinList.action";
import { getDataSuccess } from "../../redux/tiffinCategory/action/tiffinCategory.action";
import "./tiffinOrdering.scss";

const TiffinOrdering = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const stateData = useSelector((state) => state.stateListReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(tiffinList(stateData.selectedState
      ? stateData.selectedState
      : getLocalState("selectedStateId")));
    dispatch(getDataSuccess([]))
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  return (
    <div className="tiffin-ordering">
      <div
        className="loading-backdrop"
        style={{
          display: loading ? "flex" : "none",
        }}
      >
        <CircularProgress color="warning" />
      </div>
      {/* <div className="image-container">
        <img src={SplashMainImage} alt="tiffin-order" style={{height: windowWidth < 1190 ? 200 : 400}} />
        <div className="image-text">
          <h3>Tiffin Mom's</h3>
          <h1>Order Your Tiffins</h1>
          <Button onClick={() => navigate("/subscription")}>
            Buy Monthly Subscription
          </Button>
        </div>
      </div> */}
      <div className="tiffin-ordering-container">
        <h1 className="tiffin-ordering-title">Tiffin Ordering</h1>
        {Array(1)
          .fill("")
          .map((data) => (
            <TiffinOrderingCard />
          ))}
      </div>
    </div>
  );
};

export const TiffinOrderingCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tiffin = useSelector((state) => state?.tiffinListReducer?.data);

  const selectData = (item) => {
    dispatch(setSelectedTiffin(item));
    navigate("/productsinglepage");
  };

  return tiffin?.map((item) => {
    return (
      <>
        <div className="tiffin-ordering-card">
          <div className="image-section">
            <img
              src={item?.image || SplashMainImage}
              alt="tiffin-ordering-card"
            />
          </div>
          <div className="details-section">
            <h3 className="detail-title">{item?.title}</h3>
            <div className="detail-deliver-title">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.delivery_on.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            </div>
            {/* <div className="detail-deliver-address-1 detail-deliver-address">
              {item?.delivery_on}
            </div> */}
            <div className="detail-deliver-address-2 detail-deliver-address">
              Price - ${item?.price} for each Tiffin
            </div>
            <div className="detail-deliver-address-3 detail-deliver-address">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description.replace(
                    /(<? *script)/gi,
                    "illegalscript"
                  ),
                }}
              ></div>
            </div>
            <Button onClick={() => selectData(item)}>Order Now</Button>
          </div>
        </div>
      </>
    );
  });
};

export default TiffinOrdering;
