import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { getLocalState } from "../../common/localstorage";
import { DropdownArrow } from "../../assets/js/DropdownArrow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
  "Pittsburgh",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ShippingDetail = ({
  shippingAddress,
  setShippingAddress,
  isSubscription,
  diffAddress,
}) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [townShipping, setTownShipping] = React.useState(1);
  const [townBilling, setTownBilling] = React.useState(1);

  useEffect(() => {
    setPersonName([...personName]);
    // const user = localStorage.getItem("userData");
    // const parsedData = JSON.parse(user);
    // setFirstName(parsedData?.first_name);
    // setLastName(parsedData?.last_name);
    // setEmail(parsedData?.email);
  }, [diffAddress]);

  const handleFormChange = (key, value) => {
    setShippingAddress({
      ...shippingAddress,
      [key]: value,
    });
  };

  return (
    <Box component="form" noValidate autoComplete="off">
      <div className="form-field">
        <label>First Name *</label>
        <TextField
          // disabled={isSubscription}
          value={shippingAddress?.firstName}
          onChange={(e) => handleFormChange("firstName", e.target.value)}
        />
      </div>
      <div className="form-field">
        <label>Last Name *</label>
        <TextField
          // disabled={isSubscription}
          value={shippingAddress?.lastName}
          onChange={(e) => handleFormChange("lastName", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Company Name (Optional)</label>
        <TextField
          // disabled={isSubscription}
          value={shippingAddress?.companyName}
          onChange={(e) => handleFormChange("companyName", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Street Address *</label>
        <TextField
          // disabled={isSubscription}
          placeholder="House name and Street name"
          value={shippingAddress?.address1}
          onChange={(e) => handleFormChange("address1", e.target.value)}
        />
        <TextField
          // disabled={isSubscription}
          placeholder="Apartment, suit, unit..."
          value={shippingAddress?.address2}
          onChange={(e) => handleFormChange("address2", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Town / City *</label>
        <TextField
          // disabled={isSubscription}
          value={shippingAddress?.city}
          onChange={(e) => handleFormChange("city", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>State</label>
        <Select
          className="tray-filter-select"
          IconComponent={DropdownArrow}
          defaultValue={0}
          onChange={(e) => handleFormChange("state", e.target.value)}
        >
          <MenuItem value={0}>Select</MenuItem>
          <MenuItem value={'NJ'}>NJ</MenuItem>
          <MenuItem value={'NY'}>NY</MenuItem>
          <MenuItem value={'KY'}>KY</MenuItem>
          <MenuItem value={'PA'}>PA</MenuItem>
          <MenuItem value={'CH'}>CH</MenuItem>
        </Select>
      </div>
      <div className="form-field w-100">
        <label>Postcode / ZIP *</label>
        <TextField
          // disabled={isSubscription}
          value={shippingAddress?.postCode}
          onChange={(e) => handleFormChange("postCode", e.target.value)}
        />
      </div>
      <div className="form-field w-100">
        <label>Order Note (Optional)</label>
        <TextField
          // disabled={isSubscription}
          multiline
          rows={4}
          placeholder="Notes"
          value={shippingAddress?.notes}
          onChange={(e) => handleFormChange("notes", e.target.value)}
        />
      </div>
    </Box>
  );
};

export default ShippingDetail;
