export const RedCircle = (props) => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="60" cy="60" r="60" fill="#F8D1A5" {...props} />
  </svg>
);
