import {
  Button
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { AiOutlineShopping } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainImage from "../../assets/TrayMenu Image.png";
import { getLocalState } from "../../common/localstorage";
import ProductCard from "../../components/ProductCard/ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import TrayFilter from "../../components/TrayFilter/TrayFilter";
import useWindowWidth from "../../hooks/useWindowWidth";
import { productList } from "../../redux/traymenu/productList/actions/productList.action";
import { categoryList } from "../../redux/traymenu/categoryList/actions/categoryList.action";
import "./trayMenu.scss";
import { baseURL } from '../../axios'
import axios from "axios";

const TrayMenu = () => {
  const listInnerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [currPage, setCurrPage] = useState(0) // storing current page number
  const [prevPage, setPrevPage] = useState(-1) // storing prev page number
  const [category, setCategory] = useState(-1)
  const [currentCategory, setCurrentCategory] = useState(-1)
  const [counter, setCounter] = useState(0)
  const [count, setCount] = useState(0)
  const [lastList, setLastList] = useState(false);
  const [productList, setProductList] = useState([])
  const categoryState = useSelector(
    (state) => {
      return state.categoryReducer.categoryData
    }
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.productReducer.productData);
  const windowWidth = useWindowWidth();
  const stateData = useSelector((state) => state.stateListReducer);

  useEffect(() => {
    const fetchData = async () => {
      if (category !== currentCategory) {
        setCounter(0)
        setCount(0)
      }
      setCurrentCategory(category)
      let data = {
        page: counter,
        category_id: category !== -1 ? category : categoryState?.map((ele) => ele.id.toString()).join(', '),
      };
      axios.post(`${baseURL}product/product_list`, data)
        .then((res) => {
          setProductList(res.data.data);
        })
        .catch((error) => console.log('error:' + error))
    };
    fetchData();
  }, [categoryState, counter]);

  useEffect(() => {
    const fetchData = async () => {
      if (category !== currentCategory) {
        setCounter(0)
        setCount(0)
      }
      setCurrentCategory(category)
      let data = {
        page: counter,
        category_id: category !== -1 ? category : categoryState?.map((ele) => ele.id.toString()).join(', '),
      };

      axios.post(`${baseURL}product/product_list_count`, data)
        .then((res) => {
          setCount(res.data.data.count)
        })
        .catch((error) => console.log('error:' + error))
    }
    fetchData()
  }, [counter, categoryState, productList])

  useEffect(() => {
    let categoryData = {
      state_id: stateData.selectedState
    }
    if (stateData.selectedState) dispatch(categoryList(categoryData));
  }, [stateData.selectedState])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
  }, []);

  const handleCounter = (no) => {
    setLoading(true)
    window.scrollTo(0, 0)
    if (no === 1) setCounter(counter - 1)
    if (no === 2) setCounter(counter + 1)
    setTimeout(() => {
      setLoading(false)
    }, 500);
  }

  const increaseDisabled = () => counter >= (counter >= count % 10 === 0 ? (count / 10) - 1 : Math.ceil(count / 10) - 1) ? true : false

  return (
    <>
    {loading ? <div className="loading-backdrop"><CircularProgress color="warning" /></div> : '' }
    <div className="tray-menu">
      <TrayFilter setCategory={setCategory} category={category} setProductList={setProductList} productList={productList} />
      <div className="tray-menu-container">
        {/* <img
          src={MainImage}
          alt="Tray Menu Main Image"
          className="tray-menu-main-image"
          style={{height: windowWidth < 1190 ? 200 :400}}
        /> */}
        <div>
          <div className="tray-menu-products">
            {productList.length > 0 &&
              productList.map((card, key) => (
                <ProductCard
                  key={key}
                  image={card.image}
                  name={card.name}
                  price={card.price}
                  medPrice={card.medPrice}
                  largePrice={card.largePrice}
                  id={card.id}
                />
              ))}
            {false && (
              <div className="tray-menu-notification">
                <div className="tray-menu-price">
                  <AiOutlineShopping fontSize={"25px"} /> <span>$ 499</span>
                </div>
                <div className="tray-menu-view-cart">View Cart</div>
              </div>
            )}
          </div>
          <div className="next-prev-btn">
            <span className={counter <= 0 ? "arrow-prev-disabled active" : "arrow-prev"} onClick={counter <= 0 ? null : () => handleCounter(1)} disabled={counter <= 0}></span>
            <span className={increaseDisabled() ? "arrow-next-disabled" : "arrow-next"} onClick={increaseDisabled() ? null : () => handleCounter(2)} disabled={increaseDisabled()}></span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TrayMenu;
