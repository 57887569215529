import { Avatar, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GreenTriangle } from "../../assets/js/GreenTriangle";
import { RedCircle } from "../../assets/js/RedCircle";
import { RedTriangle } from "../../assets/js/RedTriangle";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import { getLocalState, setLocalState } from "../../common/localstorage";
import {
  getSubList,
  userSelectedSub,
} from "../../redux/subscription/actions/subscriptions.action";
import "./subscription.scss";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector((state) => state.stateListReducer);
  const { sub_list, sub_error, sub_details, sub_loader } = useSelector(
    (state) => state.SubscriptionReducer
  );

  useEffect(() => {
    console.log("here");
    let data = {
      state_id: stateData.selectedState
        ? stateData.selectedState
        : getLocalState("selectedStateId"),
    };
    dispatch(
      getSubList({
        state_id: data.state_id,
      })
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(sub_list);

  return (
    <div className="subscription">
      <Avatar
        className="subscription-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        sx={{ width: 76, height: 76 }}
      />
      <h1 className="subscription-page-title">Select your subscription plan</h1>
      <div className="subscription-page-cards">
        {sub_list.length > 0 &&
          sub_list?.map((item, index) => (
            <div className="subscription-page-card subscription-page-left-card">
              {index % 2 === 0 ? (
                <RedCircle
                  className="subscription-page-left-card-icon-1"
                  fill={"#FBA4A4"}
                />
              ) : (
                <RedCircle
                  className="subscription-page-left-card-icon-1"
                  width={70}
                  height={70}
                  fill="#E93434"
                />
              )}
              {index % 2 === 0 ? (
                <GreenTriangle
                  className="subscription-page-left-card-icon-2"
                  fill="#E93434"
                />
              ) : (
                <RedTriangle
                  fill="#FBA4A4"
                  className="subscription-page-left-card-icon-3"
                />
              )}
              <h3 className="subscription-card-title">{item?.name}</h3>
              <h2 className="subscription-card-amount">{"$" + item?.price}</h2>
              <hr />
              <p
                className="subscription-card-desc"
                dangerouslySetInnerHTML={{ __html: item?.description }}
              ></p>
              <Button
                className="subscription-card-button"
                onClick={() => {
                  dispatch(userSelectedSub(item));
                  setLocalState("is_subscription", true);
                  setLocalState("checkout_sub_total", item?.price);
                  setLocalState("checkout_total", item?.price);
                  navigate("/subscription-checkout", {
                    state: { fromSubscription: true },
                  });
                }}
              >
                Pay Now
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Subscription;
