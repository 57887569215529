const initialValue = {
  getData: [],
  editdata: [],
  error: "",
};

const editUserReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "EDIT_SUCCESS":
      return {
        editdata: action.payload,
        getData: action.payload,
        error: "",
      };
    case "EDIT_FAILURE":
      return {
        editdata: [],
        getData: [],
        error: action.payload,
      };
    case "GET_SUCCESS":
      return {
        getData: action.payload,
        error: "",
      };
    case "GET_FAILURE":
      return {
        getData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default editUserReducer;
