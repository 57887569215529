import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalState } from "../../common/localstorage";
import CartBanner from "../../components/CartBanner/CartBanner";
import { howItsWorks } from "../../redux/howItsWorks/actions/howItsWorks.action";

import "./WorkProcess.scss";

const WorkProcess = () => {
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.stateListReducer);
  const workdata = useSelector((state) => state.HowItsWorksReducer.data);

  useEffect(() => {
    let payload = {
      state_id: stateData.selectedState
        ? stateData.selectedState
        : getLocalState("selectedStateId"),
    };
    dispatch(howItsWorks(payload));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(workdata, "data");
  return (
    <div className="work-wrapper">
      <CartBanner />
      <div className="work-content-wrapper">
        <h2 className="section-title">How it Works</h2>
        <div className="work-content">
          <ol>
            {/* {workdata.length > 0 &&
              workdata.map((ele) => {
                return <li>{ele.data}</li>;
              })} */}
            <li>{workdata}</li>
          </ol>
          <p>
            <span>PS:</span>
            <span className="text-uppercase">
              ANY ORDERS PLACED AFTER THE TIMELINE WILL BE AUTOMATICALLY TAKEN
              ON ACCOUNT FOR THE NEXT DELIVERY SCHEDULED.
            </span>{" "}
            Our menus are changed Every Sat night for the following week.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkProcess;
