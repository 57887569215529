import { NotificationManager } from "react-notifications";
import axios from "../../axios";
import { endpoints } from "../endpoint";

export const getSuccess = (item) => {
  return {
    type: "COUPON_SUCCESS",
    payload: item,
  };
};

export const getFailure = (error) => {
  return {
    type: "COUPON_FAILURE",
    payload: error,
  };
};

export const applyCouponeCode = (payload) => (dispatch) => {
  console.log("payload:::::", payload);
  axios
    .post(endpoints.verifyPromoCode, payload)
    .then((res) => {
      if (res.data.code === 1) {
        NotificationManager.success(res.data.message);
        dispatch(getSuccess(res.data.data));
      } else {
        dispatch(getFailure("Invaild Coupon Code"));
        NotificationManager.error("Invaild Coupon Code");
      }
    })
    .catch((err) => {
      NotificationManager.error(err.message);
      dispatch(getFailure(err.message));
    });
};
