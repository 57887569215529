import axios from "../../../axios";
import { endpoints } from "../../endpoint";
import { camelCase } from "lodash";
import { getLocalState } from "../../../common/localstorage";

const sheetyHeader = new Headers();
sheetyHeader.append(
  "Authorization",
  "Basic dGlmZmlubW9tMTc5MkBnbWFpbC5jb206dGVzdEAxMjM="
);
const sheetyUrl =
  "https://api.sheety.co/bd6a4d951f51316bdce924d1a224a057/tiffinMomMenus";

export const getHomeDataSuccess = (item) => {
  return {
    type: "GET_HOME_DATA_SUCCESS",
    payload: item,
  };
};

export const getHomeDataFailure = (error) => {
  return {
    type: "GET_HOME_DATA_FAILURE",
    payload: error,
  };
};

export const homeData = (payload) => (dispatch) => {
  dispatch({
    type: "SET_IS_DONE",
  });
  const stateName = camelCase(getLocalState("selectedStateName"));
  fetch(`${sheetyUrl}/${stateName}`, {
    method: "GET",
    headers: sheetyHeader,
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: "SHEETY_MENU_DATA_SUCCESS",
        payload: json?.[stateName],
      });
    })
    .catch((err) => {
      dispatch({
        type: "SHEETY_MENU_DATA_FAILURE",
        payload: err,
      });
    });
  axios
    .post(endpoints.homeData, payload)
    .then((res) => {
      if (res.data.code === 1) {
        dispatch(getHomeDataSuccess(res.data.data));
      } else {
        dispatch(getHomeDataFailure(""));
      }
    })
    .catch((err) => {
      dispatch(getHomeDataFailure(err.message));
    });
};
