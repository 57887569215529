import { combineReducers } from "redux";
import AddtoCartReducer from "../addToCart/reducer/addToCart.reducer";
import cartListReducer from "../cartList/reducer/cartList.reducer";
import changePasswordReducer from "../changePassword/reducer/changePassword.reducer";
import contactUsReducer from "../contactUs/reducers/contact.reducer";
import editUserReducer from "../editProfile/reducers/editProfile.reducers";
import faqReducer from "../faq/reducer/faq.reducer";
import galleryDataReducer from "../gallery/reducers/gallery.controller";
import homeDataReducer from "../homeData/reducers/homedata.reducers";
import HowItsWorksReducer from "../howItsWorks/reducer/howItsWorks.reducer";
import mySubscriptionReducer from "../mySubscription/reducer/mySubscription.reducer";
import orderListReducer from "../orderList/reducer/orderList.reducer";
import stateListReducer from "../stateList/reducer/stateList.reducer";
import SubscriptionReducer from "../subscription/reducer/subscription.reducer";
import tiffinListReducer from "../tiffin/reducer/tiffinList.reducer";
import tiffinCategoryReducer from "../tiffinCategory/reducer/tiffinCategory.reducer";
import categoryReducer from "../traymenu/categoryList/reducers/categoryList.reducer";
import productReducer from "../traymenu/productList/reducers/productList.reducer";
import userReducer from "../user/userReducer";
import promocodeReducer from "../verifyCoupon/reducer";
import CommonReducer from "./CommonReducer";
import ModifierReducer from "./ModifierReducer";

const RootReducer = combineReducers({
  modifiers: ModifierReducer,
  common: CommonReducer,
  stateListReducer,
  homeDataReducer,
  editUserReducer,
  contactUsReducer,
  categoryReducer,
  productReducer,
  galleryDataReducer,
  cartListReducer,
  AddtoCartReducer,
  SubscriptionReducer,
  faqReducer,
  HowItsWorksReducer,
  tiffinListReducer,
  tiffinCategoryReducer,
  mySubscriptionReducer,
  orderListReducer,
  changePasswordReducer,
  userReducer,
  promocodeReducer,
});
export default RootReducer;
