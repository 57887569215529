import {
    GET_MODIFIER_LOADER,
    GET_MODIFIER_ERROR,
    GET_MODIFIER_SUCCESS,
    GET_MODIFIER_SET_SUCCESS,
} from '../Type'

const initialState = {
    loader: true,
    modifier_list: null,
    modifier_set_list: null,
    error: null,
}

const NotificationReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_MODIFIER_LOADER: {
            return {
                ...state,
                loader: true,
            }
        }
        case GET_MODIFIER_SUCCESS: {
            return {
                ...state,
                loader: false,
                error: null,
                modifier_list: action.payload,
            }
        }
        case GET_MODIFIER_ERROR: {
            return {
                ...state,
                loader: false,
                error: action.payload,
                modifier_list: [],
            }
        }
        case GET_MODIFIER_SET_SUCCESS: {
            return {
                ...state,
                loader: false,
                error: null,
                modifier_set_list: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default NotificationReducer
