const initialValue = {
  data: {},
  success: null,
  error: null,
};

const promocodeReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "COUPON_SUCCESS":
      console.log("SET", action.payload);
      return {
        ...state,
        data: action.payload,
        success: true,
        error: false,
      };
    case "COUPON_FAILURE":
      return {
        ...state,
        data: {},
        success: false,
        error: true,
      };
    default:
      return state;
  }
};

export default promocodeReducer;
