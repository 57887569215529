import axios from "axios"

export const getClientToken = () => {
    return axios.get('http://localhost:5000/api/generate/token')
}


export const makePayment = (data) => {
    console.log('>>>>>>>>>>>>>>>>>', data)
    return axios.post('http://localhost:5000/api/process/payment', data)
    .then(response => {
        console.log('::::::::::::::::',response)
        response.json()
    })
    .catch(error => console.log('error', error))
}