import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DropdownArrow } from "../../assets/js/DropdownArrow";
import useWindowWidth from "../../hooks/useWindowWidth";
import DeleteIcon from "@mui/icons-material/Delete";
import "./trayFilter.scss";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { categoryList } from "../../redux/traymenu/categoryList/actions/categoryList.action";
import { getLocalState } from "../../common/localstorage";
import { productList } from "../../redux/traymenu/productList/actions/productList.action";
import { baseURL } from '../../axios'
import axios from "axios";

const MenuProps = {
  PaperProps: {
    style: {
      display: "none",
    },
  },
};

const TrayFilter = ({ setCategory, category, setProductList, productList }) => {
  const [filterHeight, setFilterHeight] = useState();
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const windowWidth = useWindowWidth();
  const filterContainer = useRef();
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.stateListReducer);

  const categoryData = useSelector(
    (state) => state.categoryReducer.categoryData
  );

  const handleSearch = () => {
    setShowFilterDrawer(false);
    if (category !== -1) {
      let data = {
        page: 0,
        category_id: category,
      };
      axios.post(`${baseURL}product/product_list`, data)
        .then((res) => {
          setProductList(res.data.data)
          setCategory(category)
        })
        .catch((error) => console.log('error:' + error))
    };
  }


  useEffect(() => {
    if (windowWidth ?? window.innerWidth >= 700) {
      var myInterval = setInterval(() => {
        setFilterHeight(filterContainer?.current?.clientHeight);
        if (filterContainer?.current?.clientHeight) {
          clearInterval(myInterval);
        }
      }, 100);
    }

    let data = {
      state_id: 1,
    };
    dispatch(categoryList(data));
  }, []);

  const handleDelete = () => { };

  const renderSelect = () => (
    <>
      <FormControl className="tray-filter-select-container">
        <div className="tray-filter-select-title">Food Category</div>
        <Select
          className="tray-filter-select"
          IconComponent={DropdownArrow}
          defaultValue={-1}
          onChange={(e) => setCategory(e.target.value)}
        >
          <MenuItem value={-1}>Select</MenuItem>

          {categoryData.length > 0 &&
            categoryData.map((data, key) => {
              return (
                <MenuItem value={data.id} key={key}>
                  {data.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );

  return (
    <>
      <div
        className="tray-filter"
      >
        <h3 className="tray-filter-title">Product List</h3>
        <div ref={filterContainer} className="tray-filter-main-container">
          <div className="tray-filter-container">
            {renderSelect()}
            <Button
              className="try-filter-search"
              style={{
                transform: `translate(-50%, ${filterContainer?.current?.clientHeight / 2 - 25
                  }px)`,
              }}
              onClick={() => handleSearch()}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrayFilter;
