const initialValue = {
  categoryData: {},
  error: "",
};

const categoryReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "GET_CATEGORY_SUCCESS":
      return {
        ...state,
        categoryData: action.payload.data,
        error: "",
      };
    case "GET_CATEGORY_FAILURE":
      return {
        ...state,
        categoryData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
