import axios from "../../../../axios";
import { endpoints } from "../../../endpoint";

export const getCategorySuccess = (data) => {
  return {
    type: "GET_CATEGORY_SUCCESS",
    payload: data,
  };
};

export const getCategoryFailure = (error) => {
  return {
    type: "GET_CATEGORY_FAILURE",
    payload: error,
  };
};

export const categoryList = (payload) => (dispatch) => {
  axios
    .post(endpoints.categoryList, payload)
    .then((res) => {
      if (res.data.code === 1) {
        dispatch(getCategorySuccess(res.data));
      } else {
        // NotificationManager.error(res.data.message);
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getCategoryFailure(err.message));
    });
};
