import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getGalleryDataSuccess = (item) => {
  return {
    type: "GET_GALLERY_DATA_SUCCESS",
    payload: item,
  };
};

export const getGalleryDataFailure = (error) => {
  return {
    type: "GET_GALLERY_DATA_FAILURE",
    payload: error,
  };
};

export const galleryData = (payload) => (dispatch) => {
  axios
    .post(endpoints.gallery, payload)
    .then((res) => {
      if (res.data.code === 1) {
        dispatch(getGalleryDataSuccess(res.data));
      } else {
        // NotificationManager.error('Something went wrong!');
      }
    })
    .catch((err) => {
      // NotificationManager.error(err.message);
      dispatch(getGalleryDataFailure(err.message));
    });
};
