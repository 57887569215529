import { Avatar, Button, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect } from "react";
import MainPhoto from "../../assets/NonAuthImage.png";
import TiffinMomLogo from "../../assets/TiffinMom-Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./resetpassword.scss";
import { CONSTANTS } from "../../common/constants";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../redux/actions/auth";
import { getLocalState } from "../../common/localstorage";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, CONSTANTS.PASSWORD_MIN_8)
      .required(CONSTANTS.REQUIRED_FIELD),
    confirmpassword: Yup.string()
      .required(CONSTANTS.REQUIRED_FIELD)
      .oneOf([Yup.ref("password"), null], CONSTANTS.PASSWORD_MATCH),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.email = location.state.email;
      dispatch(resetPassword(values, navigate));
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   if (getLocalState("token")) {
  //     navigate(-1);
  //   }
  // }, []);

  if (location?.state === {} || !location?.state?.email) {
    navigate("/forgotpassword");
  }

  return (
    <div className="reset-password-page">
      <Avatar
        className="reset-password-page-logo"
        alt="Tiffin Mom Logo"
        src={TiffinMomLogo}
        onClick={() => navigate("/splash")}
        sx={{ width: 76, height: 76 }}
      />
      <div className="reset-password-page-container">
        <div className="reset-password-page-leftbox">
          <img
            src={MainPhoto}
            alt="reset-password Page"
            className="reset-password-page-photo"
          />
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="reset-password-page-rightbox"
        >
          <h1 className="reset-password-page-right-title">Reset Password</h1>
          <div className="reset-password-page-right-new-password-container">
            <h3 className="reset-password-page-right-new-password-label">
              Enter New Password
            </h3>
            <TextField
              type="password"
              className="reset-password-page-right-new-password-input"
              name="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </div>
          <div className="reset-password-page-right-confirm-new-password-container">
            <h3 className="reset-password-page-right-confirm-new-password-label">
              Confirm New Password
            </h3>
            <TextField
              type="password"
              className="reset-password-page-right-confirm-new-password-input"
              name="confirmpassword"
              variant="outlined"
              value={formik.values.confirmpassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmpassword &&
                Boolean(formik.errors.confirmpassword)
              }
              helperText={
                formik.touched.confirmpassword && formik.errors.confirmpassword
              }
            />
          </div>
          <Button type="submit" className="reset-password-page-right-button">
            Reset
          </Button>
          <div className="reset-password-page-remember-password">
            Remember Password{" "}
            <span>
              <Link to={"/signin"}>Back to Sign in</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
