import { Button, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ContactUsImage } from "../../assets/js/ContactUsImage";
import { contactUs } from "../../redux/contactUs/actions/contact.action";
import { getLocalState, setLocalState } from "../../common/localstorage";
import "./contactUs.scss";

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [stateId, setStateId] = useState(1);
  const stateData = useSelector((state) => state.stateListReducer);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    let data = {
      name: name,
      email: email,
      message: message,
    };
    dispatch(contactUs(data));
  };

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!getLocalState("selectedStateId")) {
      navigate("/splash");
    }
    setStateId(
      stateData.selectedState
        ? stateData.selectedState
        : getLocalState("selectedStateId")
    );
  }, []);

  // useEffect(() => {
  //   if (!getLocalState("token")) {
  //     navigate("/signin");
  //   }
  // }, []);

  const getAddress = (stateId) => {
    if (stateId == 1) {
      return "1187 Freedom Rd C-105, Cranberry Twp, PA 16006";
    } else if (stateId == 3) {
      return "28331 Dodge Drive Warrenville, IL, 60555";
    } else if (stateId == 4) {
      return "2021 Lincoln Hwy, Somerset, NJ 08873";
    } else if (stateId == 6) {
      return "2021 Lincoln Hwy, Somerset, NJ 08873";
    } else if (stateId == 7) {
      return "3320 E market street, York, PA-17402";
    }
  };

  const getEmail = (stateId) => {
    if (stateId == 1) {
      return "orders.pitts@tiffinmom.com";
    } else if (stateId == 3) {
      return "orders.chicago@tiffinmom.com";
    } else if (stateId == 4) {
      return "orders.newjersey@tiffinmom.com";
    } else if (stateId == 6) {
      return "orders.newyork@tiffinmom.com";
    } else if (stateId == 7) {
      return "orders.york@tiffinmom.com";
    }
  };

  const getNumber = (stateId) => {
    if (stateId == 1) {
      return "+1 724-591-8888";
    } else if (stateId == 3) {
      return "+1 732-960-1808";
    } else if (stateId == 4) {
      return "+1 732 960 1815";
    } else if (stateId == 6) {
      return "+1 732 960 1808";
    } else if (stateId == 7) {
      return "+1 502 515 0527";
    }
  };

  const getLocationUrl = (stateId) => {
    if (stateId == 1) {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8778.321994733915!2d-80.12169393687766!3d40.68344342037571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883462c6e2304d43%3A0xbfe24eb0ba3be7f3!2s1187%20Freedom%20Rd%2C%20Cranberry%20Twp%2C%20PA%2016066%2C%20USA!5e0!3m2!1sen!2sin!4v1661092110900!5m2!1sen!2sin";
    } else if (stateId == 3) {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.5123715566074!2d-88.20436219999999!3d41.7957216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880ef8fcdf96de23%3A0x37d5fc2c01dda012!2sSankalp%20Restaurant!5e0!3m2!1sen!2sin!4v1706124754804!5m2!1sen!2sin";
    } else if (stateId == 4) {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.7773620654307!2d-74.51383948428864!3d40.45806437936034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c33943e722df%3A0x95987d6261d4700e!2sTiffinmom!5e0!3m2!1sen!2sin!4v1668926058388!5m2!1sen!2sin";
    } else if (stateId == 6) {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.7773620654307!2d-74.51383948428864!3d40.45806437936034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c33943e722df%3A0x95987d6261d4700e!2sTiffinmom!5e0!3m2!1sen!2sin!4v1668926058388!5m2!1sen!2sin";
    } else if (stateId == 7) {
      return "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.321486555111!2d-76.6592971!3d39.9789204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c88f7419fda395%3A0xd2c7129795cd0b6c!2s3320%20E%20Market%20St%2C%20York%2C%20PA%2017402%2C%20USA!5e0!3m2!1sen!2sin!4v1683228065818!5m2!1sen!2sin";
    }
  };

  return (
    <div className="contactus">
      <div className="contactus-map-area">
        <iframe
          src={getLocationUrl(stateId)}
          width="100%"
          height="600"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          area-hidden="false"
          tabIndex={"0"}
        />
      </div>
      <div className="contactus-details">
        <h1 className="contactus-details-title">Get in Touch</h1>
        <div className="contactus-details-container">
          <h3>Contact Information</h3>
          <div className="contactus-location contactus-data">
            <GoLocation />
            <p>{getAddress(stateId)}</p>
          </div>
          <div className="contactus-email contactus-data">
            <MdOutlineEmail />
            <p>{getEmail(stateId)}</p>
            {/* <Link className="contactus-number contactus-data" to="#" style={{ color: 'black', textDecoration: 'none' }} onClick={(e) => {
              window.location.href = 'mailto:orders.pitts@tiffinmom.com';
              e.preventDefault();
            }}>{'orders.pitts@tiffinmom.com'}</Link> */}
          </div>
          <div className="contactus-number contactus-data">
            <IoCallOutline />
            <p>{getNumber(stateId)}</p>
          </div>
        </div>
      </div>
      <div className="contactus-form-container">
        <div className="contactus-form">
          <div className="form-input">
            <InputLabel>Name*</InputLabel>
            <TextField
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-input">
            <InputLabel>Email*</InputLabel>
            <TextField
              variant="outlined"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-input">
            <InputLabel>Message*</InputLabel>
            <TextField
              variant="outlined"
              multiline
              rows={4}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Button className="form-submit-btn" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
        <ContactUsImage />
      </div>
    </div>
  );
};

export default ContactUs;
