import {
  GET_SUB_LIST_ERROR,
  GET_SUB_LIST_SUCESS,
  GET_SUB_LOADER,
  POST_SUB_ERROR,
  POST_SUB_SUCESS,
} from "../../Type";

const initialValue = {
  sub_list: [],
  sub_error: null,
  sub_details: null,
  sub_loader: false,
  isDone: false,
  sub_values: {},
};

const SubscriptionReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "SET_IS_DONE":
      return {
        ...initialValue,
        isDone: false,
      };
    case GET_SUB_LOADER:
      return {
        ...initialValue,
        sub_loader: true,
        isDone: false,
      };
    case GET_SUB_LIST_SUCESS:
      return {
        ...initialValue,
        sub_loader: false,
        sub_list: action.payload,
        sub_error: null,
        isDone: true,
      };
    case GET_SUB_LIST_ERROR:
      return {
        ...initialValue,
        sub_loader: false,
        sub_list: [],
        sub_error: action.payload,
        isDone: true,
      };
    case POST_SUB_SUCESS:
      return {
        ...initialValue,
        sub_details: action.payload,
        sub_error: null,
        isDone: true,
      };
    case POST_SUB_ERROR:
      return {
        ...initialValue,
        sub_details: null,
        sub_error: action.payload,
        isDone: true,
      };
    case "USER_SELECTED_SUB":
      return {
        ...initialValue,
        sub_values: action.payload,
      };
    case "DELETE_USER_SELECTED_SUB":
      return {
        ...initialValue,
        sub_values: {},
      };
    default:
      return state;
  }
};

export default SubscriptionReducer;
