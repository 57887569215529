import { Button } from "@mui/material";
import { capitalize, lowerCase } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useWindowWidth from "../../hooks/useWindowWidth";
import Card from "./Cards/Card";
import "./homePageDishes.scss";

const HomePageDishes = () => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();
  const menudata = useSelector((state) => state.homeDataReducer.menu);
  const sheetyMenuData = useSelector(
    (state) => state.homeDataReducer.sheetyMenu
  );
  const datePeriod = useSelector((state) => state.homeDataReducer.sheetyMenu[0]?.date);
  const [filteredMenuData, setFilteredMenuData] = useState([]);

  console.log("datePeriod", datePeriod);

  useEffect(() => {
    let mainData = [];
    for (let i = 0; i < sheetyMenuData.length; i++) {
      const allMainTypes = mainData.map((data) => lowerCase(data.name));
      if (allMainTypes.includes(lowerCase(sheetyMenuData[i].type))) {
        const mainDataIndex = mainData.findIndex(
          (data) => lowerCase(data?.name) === lowerCase(sheetyMenuData[i]?.type)
        );
        const totalDaysArr = mainData[mainDataIndex].data;
        for (let j = 0; j < totalDaysArr.length; j++) {
          mainData[mainDataIndex].data[j].menu.push({
            name: sheetyMenuData[i]?.[
              lowerCase(mainData[mainDataIndex].data[j].day)
            ],
          });
        }
      } else {
        const allDays = Object.keys(sheetyMenuData[i]);
        const dishData = [];
        allDays.forEach((ele) => {
          if (!ele.includes('type') && !ele.includes('date') && !ele.includes('suggestions') && ele !== 'id') {
            dishData.push({
              day: ele.length > 1 ? ele[0].toUpperCase() + ele.slice(1) : ele,
              menu: [{ name: sheetyMenuData[i][ele] }],
            });
          }
        })
        // if (allDays.includes("monday")) {
        // }
        // if (allDays.includes("tuesday")) {
        //   dishData.push({
        //     day: "Tuesday",
        //     menu: [{ name: sheetyMenuData[i].tuesday }],
        //   });
        // }
        // if (allDays.includes("wednesday")) {
        //   dishData.push({
        //     day: "Wednesday",
        //     menu: [{ name: sheetyMenuData[i].wednesday }],
        //   });
        // }
        // if (allDays.includes("thursday")) {
        //   dishData.push({
        //     day: "Thursday",
        //     menu: [{ name: sheetyMenuData[i].thursday }],
        //   });
        // }
        // if (allDays.includes("friday")) {
        //   dishData.push({
        //     day: "Friday",
        //     menu: [{ name: sheetyMenuData[i].friday }],
        //   });
        // }
        // if (allDays.includes("saturday")) {
        //   dishData.push({
        //     day: "Saturday",
        //     menu: [{ name: sheetyMenuData[i].saturday }],
        //   });
        // }
        // if (allDays.includes("sunday")) {
        //   dishData.push({
        //     day: "Sunday",
        //     menu: [{ name: sheetyMenuData[i].sunday }],
        //   });
        // }

        mainData.push({
          name: capitalize(sheetyMenuData[i].type),
          data: dishData,
        });
      }
    }
    setFilteredMenuData([...mainData]);
  }, [menudata, sheetyMenuData]);

  return (
    <div className="homepage-dishes">
      <Button
        className="homepage-dishes-order-btn"
        style={{ marginBottom: 20 }}
        onClick={() => navigate("/tiffinordering")}
      >
        Order Now
      </Button>
      <h1 className="homepage-dishes-title">
        Menu For Week Starting - <span>{datePeriod}</span>
      </h1>
      {windowWidth < 751 ? (
        <Swiper pagination={true} modules={[Pagination]}>
          {filteredMenuData.length > 0 &&
            filteredMenuData.map((ele) => (
              <SwiperSlide>
                <Card name={ele.name} data={ele.data} />
              </SwiperSlide>
            ))}
        </Swiper>
      ) : (
        <div className="homepage-dishes-cards">
          {filteredMenuData.length > 0 &&
            filteredMenuData
              .map((ele, i) => {
                if (i % 2 === 0) {
                  if (i !== filteredMenuData.length - 1) {
                    return <div className="homepage-dishes-card-container">
                      <Card name={filteredMenuData[i].name} data={filteredMenuData[i].data} />
                      <Card name={filteredMenuData[i + 1].name} data={filteredMenuData[i + 1].data} />
                    </div>
                  } else {
                    return <div className="homepage-dishes-card-container">
                      <Card name={filteredMenuData[i].name} data={filteredMenuData[i].data} />
                    </div>
                  }
                }
              })
          }
        </div>
      )}
      <Button
        className="homepage-dishes-order-btn"
        onClick={() => navigate("/tiffinordering")}
      >
        Order Now
      </Button>
    </div>
  );
};

export default HomePageDishes;
