import { NotificationManager } from "react-notifications";
import axios from "../../../axios";
import { endpoints } from "../../endpoint";

export const getMySubscriptionSuccess = (item) => {
    return {
      type: "GET_MY_SUBSCRIPTION_SUCCESS",
      payload: item,
    };
  };
  
  export const getMySubscriptionFailure = (error) => {
    return {
      type: "GET_MY_SUBSCRIPTION_FAILURE",
      payload: error,
    };
  };

  export const mySubscription = () => (dispatch) => {
    axios
      .get(endpoints.mySubscription)
      .then((res) => {
        if (res.data.code === 1) {
            console.log("response data::::",res)
          // NotificationManager.success(res.data.message);
          dispatch(getMySubscriptionSuccess(res.data.data));
        } else {
          // NotificationManager.error(res.data.message);
        }
      })
      .catch((err) => {
        // NotificationManager.error(err.message);
        dispatch(getMySubscriptionFailure(err.message));
      });
  };

  
