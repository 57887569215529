import React from "react";
import SamplePhoto from "../../assets/GalleryPhoto2.jpg";
import SamplePhoto2 from "../../assets/Rectangle 3.png";
import "./galleryImagesGrid.scss";

const GalleryImagesGrid = ({ imageList, selectedTab, imageNo }) => {
  return (
    <div className="images-grid-container">
      {imageList.map((data) => (
        <div className="image-container">
          {/* {selectedTab % 2 === 0 ? ( */}
          <img src={data.image} />
          {/* ) : (
              <img src={data.image} />
            )} */}
        </div>
      ))}
    </div>
  );
};

export default GalleryImagesGrid;
