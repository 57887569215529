export const endpoints = {
  sign_up: "authentication/signup",
  log_in: "authentication/signin",
  logout: "authentication/logout",
  sendEmail: "authentication/send_otp",
  resetPassword: "authentication/reset_password",
  verifyOtp: "/authentication/verify_otp",
  stateList: "authentication/state_list",

  homeData: "home/home_data",

  editProfile: "authentication/edit_profile",
  getProfile: "authentication/get_profile",
  passwordChange: "authentication/change_password",

  tiffinList: "tiffin/tiffin_list",
  tiffinCategory: "tiffin/tiffin_detail",
  categoryList: "product/category_list",
  productList: "product/product_list",
  cartList: "product/cart_list",
  addToCart: "product/add_to_cart",
  orderList: "product/my_order",
  verifyPromoCode: "product/verify_promocode",
  gallery: "home/gallery",
  contactUs: "authentication/contact_us",
  placeOrderDetails: "product/place_order",
  faq: "home/faqs",
  howItsWorks: "home/how_it_work_page",
  subList: "subscription/subscription_list",
  mySubscription: "subscription/my_subscription",
  usersub: "subscription/user_subscription",
};
