const initialValue = {
  data: [],
  error: "",
};

const AddtoCartReducer = (state = initialValue, action) => {
  switch (action.type) {
    case "ADD_TO_CART_SUCCESS":
      return {
        ...state,
        data: action.payload,
        error: "",
      };
    case "ADD_TO_CART_FAILURE":
      return {
        ...state,
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AddtoCartReducer;
