import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeBackgroundMobileImage from "../../assets/HomeBackgroundImage.png";
import HomeBackgroundMobileImage2 from "../../assets/HomeBackgroundMobileImage2.jpeg";
import HomeBackgroundImage from "../../assets/home_banner1.jpeg";
import HomeBanner2 from "../../assets/home_banner2.png";
import { getLocalState } from "../../common/localstorage";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./homePageInfo.scss";

const HomePageInfo = () => {
  const navigate = useNavigate();
  const [stateId, setStateId] = useState(1);
  const windowWidth = useWindowWidth();
  const stateData = useSelector((state) => state.stateListReducer);

  useEffect(() => {
    setStateId(stateData.selectedState
      ? stateData.selectedState
      : getLocalState("selectedStateId"));
  }, []);

  const getNumber = (id) => {
    console.log("SYATE", id)
    switch (id) {
      case '1' | 1:
        return '+1 412 730 5488';
      case '3' | 3:
        return '+1 502 515 0527';
      case '6' | 6:
        return '+1 732 960 1808';
      case '7' | 7:
        return '+1 502 515 0527';
      default:
        return '+1 732 960 1815';
    }
  }

  const getWeedayDayPrice = (id) => {
    switch (id) {
      case '1' | 1:
        return '49.99';
      case '3' | 3:
        return '49.99';
      case '6' | 6:
        return '69.99';
      case '7' | 7:
        return '69.99';
      default:
        return '49.99';
    }
  }

  const getWeeklyPrice = (id) => {
    switch (id) {
      case '1' | 1:
        return '85.99';
      case '3' | 3:
        return '85.99';
      case '6' | 6:
        return '89.99';
      case '7' | 7:
        return '89.99';
      default:
        return '79.99';
    }
  }

  const getWeeklyDays = (id) => {
    switch (id) {
      case '1' | 1:
        return 'Delivery Only On Tuesday';
      case '3' | 3:
        return 'Delivery On Monday';
      case '4' | 4:
        return 'Delivery On Monday and Thursday';
      case '6' | 6:
        return 'Delivery only on Thursday’s';
      case '7' | 7:
        return 'Delivery only on Tuesday\'s';
      default:
        return 'Delivery On Monday, Wednesday & Friday';
    }
  }

  const getWeedayDays = (id) => {
    switch (id) {
      case '1' | 1:
        return 'Delivery On Tuesday and Friday';
      case '3' | 3:
        return 'Delivery On Monday';
      case '4' | 4:
        return 'Delivery On Monday and Thursday';
      case '6' | 6:
        return `Delivery only on Thursday's`;
      case '7' | 7:
        return `Delivery only on Tuesday's`;
      default:
        return `Delivery On Monday, Wednesday & Friday`;
    }
  }

  return (
    <div className="homepage-info">
      <div className="homepage-info-cards-container">
        {windowWidth < 926 ? (
          <img src={HomeBackgroundImage} alt="Home Page Info" />
        ) : (
          <img src={HomeBackgroundMobileImage} alt="Home Page Info" />
        )}
        <div className="homepage-info-cards">
          <div className="homepage-info-card">
            <div className="homepage-info-card-price">${getWeedayDayPrice(stateId)}</div>
            <h3>Weekday Tiffin Service</h3>
            <h5>{getWeedayDays(stateId)}</h5>
            {stateId == 6 ? <h6>in between 4 PM - 10 PM</h6> : <h6>in Between 4:00 PM to 9:00 PM</h6>}
            {stateId == 6 && <h6 className="warning">
              Make Sure Your Cart Total Goes Above $69.99
            </h6>}
            <Button
              className="homepage-dishes-order-btn"
              style={{ marginBottom: 20, alignSelf: 'center', marginTop: 40 }}
              onClick={() => navigate("/tiffinordering")}
            >
              Order Now
            </Button>
          </div>
          <div className="homepage-info-card">
            <div className="homepage-info-card-price">${getWeeklyPrice(stateId)}</div>
            <h3>Weekly Tiffin Service</h3>
            <h5>{getWeeklyDays(stateId)}</h5>
            {stateId == 6 ? <h6>in between 4 PM - 10 PM</h6> : <h6>in Between 4:00 PM to 9:00 PM</h6>}
            <h6 className="warning">
              Last order accepted by 11:59 PM a day before
            </h6>
            <h1 style={{ marginTop: 30, fontSize: 24, alignSelf: 'center', color: 'red' }}>Ph : {getNumber(stateId)}</h1>
          </div>
        </div>
      </div>
      <div className="homepage-info-details-container">
        <img
          src={
            windowWidth < 926
              ? HomeBackgroundMobileImage2
              : HomeBanner2
          }
          alt="Home Page Info "
        />
        <div className="homepage-info-details">
          <h4>
            Delivery timing can vary upon weather conditions, those delays are
            not eligible for refund.
          </h4>
          {/* <h5>
            Menu For the Week Starting on <span>25th April 2022</span>
          </h5> */}
        </div>
      </div>
    </div>
  );
};

export default HomePageInfo;
